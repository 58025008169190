<template>
	<Fragment>
		<SortAndFilterCakesMobile
			@close-sort-and-filter-modal="isFilterAndSortMobileDisplay=false"
			@on-submitted-modal-filter-mobile="onSubmittedMobileModal"
			:is-display="isFilterAndSortMobileDisplay"
			:selected-cake-type="selectedType"
			:selected-sort-type="currentSortType"
		>
		</SortAndFilterCakesMobile>

		<div class="category-header">
			<div class="category-header-left">
				<template v-if="breadcrumbs">
					<h3 v-if="cakesObject.cakesResult">{{ cakesObject.totalProducts }} {{ cakesObject.totalProducts > 1 ? 'Results' : 'Result' }} for "{{ term }}"</h3>
					<Breadcrumbs
						v-if="breadcrumbs[0].route && breadcrumbs[1].text"
						:items="breadcrumbs"
					></Breadcrumbs>

					<SortContainerMobile
						:totalCakes="cakesObject.totalProducts"
						@click-filter-toggle-mobile="isFilterAndSortMobileDisplay = true"
					>
					</SortContainerMobile>
				</template>
			</div>

			<div class="category-header-right">
				<SortContainer @on-sort-option-click="sortOptionClickedHandler"></SortContainer>
			</div>
		</div>

		<div class="category-content">
			<div class="category-content-left">
				<StyleSelection
					:selectedValue="selectedType"
					@select-cake-type="selectedCakeTypeHandler">
				</StyleSelection>
			</div>

			<div class="category-content-right">
				<template v-if="cakesObject.totalProducts > 0 && !isLoading">
					<Products :itemType="'product'" :products="cakesObject.cakesResult"></Products>
					<Pages @clicked-page-item="pageClickedHandler" :totalPages="cakesObject.totalPages" :currentPage="currentPage"></Pages>
				</template>

				<template v-if="cakesObject.totalProducts === 0 && !isLoading">
					<h3>Oops!!! Cakes not found!!!</h3>
				</template>

				<template>
					<Spinner :isDisplay="isLoading"></Spinner>
				</template>
			</div>
		</div>
	</Fragment>
</template>

<script>
import { Fragment } from "vue-fragment";
import { mapState } from "vuex";

import Product from "@/services/Product";
import Title from "@/components/UI/Title/Title";
import Breadcrumbs from "@/components/UI/Breadcrumbs/Breadcrumbs";
import StyleSelection from "@/components/StyleSelection/StyleSelection";
import Products from "@/components/Products/Products";
import Pages from "@/components/UI/Pages/Pages";
import Spinner from "@/components/UI/Spinner/Spinner";
import SortContainer from "@/components/UI/SortContainer/SortContainer";
import SortContainerMobile from "@/components/UI/SortContainerMobile/SortContainerMobile";
import SortAndFilterCakesMobile from "@/components/UI/SortAndFilterCakesMobile/SortAndFilterCakesMobile";

export default {
	components: { Fragment, Title, Breadcrumbs, StyleSelection, Products, Pages, Spinner, SortContainer, SortContainerMobile, SortAndFilterCakesMobile },
	name: "CakeResultPage",
	data () {
		return {
			term: '',
			breadcrumbs: null,
			currentPage: 1,
			currentSortType: 'search-magnitude',
			selectedType: 'All',
			cakesObject: {},
			isSortOptionsDisplay: false,
			isLoading: false,
			isFilterAndSortMobileDisplay: false
		}
	},
	created() {
		this.term = this.$router.history.current.query.term;
		this.searchProducts();
	},
	watch: {
		async $route(to) {
			this.term = to.query.term;
			await this.searchProducts();
		},
		async propertiesComputed() {
			const productInstance = new Product();
			this.isLoading = true;
			const ITEMS_PER_PAGE = this.isMobileScreen ? 15 : 20;
			const result = await productInstance.searchProductsByName(this.term, this.selectedType, this.currentSortType, this.currentPage, ITEMS_PER_PAGE);
			this.cakesObject = {...result};
			this.isLoading = false;
		},
		async isMobileScreen() {
			await this.searchProducts();
		}
	},
	computed: {
		...mapState({
			bakerySlug: state => state.bakery.bakery.slug,
			isMobileScreen: state => state.layout.isMobileScreen
		}),
		propertiesComputed() {
			return `${this.currentPage}|${this.currentSortType}|${this.selectedType}`;
		}
	},
	methods: {
		searchProducts: async function() {
			const productInstance = new Product();
			this.isLoading = true;
			this.selectedType = 'All';
			this.currentPage = 1;
			let result;
			const ITEMS_PER_PAGE = this.isMobileScreen ? 15 : 20;
			const breadcrumbs = [];
			breadcrumbs.push(
				{
					text: 'Home',
					route: `/bakeries/${this.bakerySlug}`
				},
				{
					text: "Cake Result",
					route: `/cakes?term=${this.term}`
				}
			);
			this.breadcrumbs = [...breadcrumbs];
			result = await productInstance.searchProductsByName(this.term, this.selectedType, this.currentSortType, this.currentPage, ITEMS_PER_PAGE);
			this.cakesObject = {...result};
			this.isLoading = false;
		},
		onSubmittedMobileModal: function(selectedSortType, selectedCakeType) {
			this.isFilterAndSortMobileDisplay = false;
			this.currentPage = 1;
			this.selectedType = selectedCakeType;
			this.currentSortType = selectedSortType;
		},
		sortOptionClickedHandler: function(value) {
			this.currentSortType = value;
		},
		selectedCakeTypeHandler: function(type) {
			this.selectedType = type;
			this.currentPage = 1;
		},
		pageClickedHandler: function(page) {
			this.currentPage = page;
		}
	}
}
</script>

<style scoped>

</style>
