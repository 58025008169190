<template>
  <p @click="clickPageItemHandler(pageNumber)" :class="{ 'active': active }">Page {{ pageNumber }}</p>
</template>

<script>
  export default {
    props: {
      pageNumber: {
        type: Number
      },
			active: {
				type: Boolean,
				default: false
			}
    },
    methods: {
      clickPageItemHandler(pageNumber) {
        this.$emit('click-item', pageNumber);
      }
    }
  }
</script>

<style lang="scss" scoped>
  p {
    margin: 0 !important;
    font-weight: 550;
		color: black;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    padding-left: 0.5em;
  }

	.active {
		background-color: rgb(244, 240, 237);
	}
</style>
