<template>
  <div class="logo" :class="{ 'logo-is-scrolled': isScrolled, 'shrink': isScrolled, 'expand': !isScrolled }">
    <div class="icon" v-if="type === 'mobile'" @click="TOGGLE_SIDEDRAW_MOBILE">
      <i class="fas fa-bars fa-2x"></i>
    </div>
    <router-link :to="'/bakeries/' + slug">
      <img :src="image"/>
    </router-link>
  </div>
</template>

<script>
  import { mapMutations, mapState } from 'vuex';
  import { TOGGLE_SIDEDRAW_MOBILE } from '../../../../store/mutation-types';

  export default {
    data() {
      return {
        defaultLogo: 'https://s3.amazonaws.com/bakeries/logos/67208/original.png?1607359135'
      }
    },
    props: {
      type: {
        type: String,
        default: 'desktop'
      },
      image: {
        type: String,
        require: true,
        default: 'https://s3.amazonaws.com/bakeries/logos/67208/original.png?1607359135'
      },
      slug: {
        type: String,
        require: true,
        default: 'sprinkles-and-bling'
      }
    },
    computed: {
      ...mapState({
        isScrolled: state => state.layout.isScrolledDown
      })
    },
    methods: {
      ...mapMutations('layout', [TOGGLE_SIDEDRAW_MOBILE])
    }
  }
</script>

<style lang="scss" scoped>
  .logo {
    display: flex;
		margin-right: 3em;
    flex-direction: row;
    height: 5em;

    @include small-screen {
      padding-left: 1em;
    }

    @include mobile {
      width: 40%;
    }

		@include tablet {
			width: 30%;
		}

    .icon {
      height: 100%;
    }

    a {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;

      @include small-mobile {
        img {
          max-height: 60%;
          padding-left: 0.5em;
        }
      }

      @include mobile {
        img {
          max-height: 70%;
          padding-left: 0.5em;
        }
      }

      @include tablet {
        img {
          max-height: 80%;
          padding-left: 0.5em;
        }
      }

      @include large-screen {
        img {
          max-height: 80%;
        }
      }
    }
  }

  @include large-screen {
    .logo-is-scrolled {
      height: 3em;
    }
  }

  @include large-screen {
    .shrink {
      animation-name: shrink;
      animation-duration: 0.5s;
    }

    .expand {
      animation-name: expand;
      animation-duration: 0.5s;
    }
  }


  @keyframes shrink {
    from { height: 5em; }
    to { height: 3em; }
  }

  @keyframes expand {
    from { height: 3em; }
    to { height: 5em; }
  }
</style>
