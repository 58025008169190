<template>
  <span :class="{ 'previous-button': !isNextButton }">
    <RightArrow></RightArrow>
  </span>
</template>

<script>
  import RightArrow from '@/assets/images/right-arrow.svg';

  export default {
    components: { RightArrow },
    props: {
      isNextButton: {
        type: Boolean,
        default: true
      }
    }
  }
</script>

<style lang="scss">
  .previous-button svg {
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }
</style>
