<template>
  <div class="carousel-container" :style="{ backgroundColor: backgroundColor }">
    <VueSlickCarousel v-bind="settings" v-if="cakes.length" ref="carousel">
      <template v-if="carouselType === 'categoryItems'">
        <CategoryItem v-for="(cake, index) in cakes" :key="index" :categorySlug="cake.categorySlug" :image="cake.image" :name="cake.name"></CategoryItem>
      </template>

      <template v-else-if="carouselType === 'cakeItems'">
        <CakeItem v-for="(cake, index) in cakes" :key="index" :image="cake.image" :name="cake.name"></CakeItem>
      </template>

      <template #prevArrow>
        <div class="custom-arrow">
          <SlideNavigationItem></SlideNavigationItem>
        </div>
      </template>

      <template #nextArrow>
        <div class="custom-arrow">
          <SlideNavigationItem :isNextButton="false"></SlideNavigationItem>
        </div>
      </template>
    </VueSlickCarousel>
  </div>
</template>

<script>
  import VueSlickCarousel from 'vue-slick-carousel';
  import 'vue-slick-carousel/dist/vue-slick-carousel.css';
  import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';
  import SlideNavigationItem from './SlideNavigationItem/SlideNavigationItem';
  import CategoryItem from './CategoryItem/CategoryItem';
  import CakeItem from './CakeItem/CakeItem';

  export default {
    components: { VueSlickCarousel, CakeItem, SlideNavigationItem, CategoryItem },
    props: {
      cakes: {
        type: Array
      },
      carouselType: {
        type: String,
        default: 'categoryItems'
      },
      backgroundColor: {
        type: String,
        default: "white"
      }
    },
    data() {
      return {
        isFirstSlide: true,
        isLastSlide: false,
        currentSlide: 1,
        settings: {
          "dots": false,
          "speed": 500,
          "slidesToShow": 5,
          "slidesToScroll": 5,
          "initialSlide": 0,
          "infinite": true,
          "responsive": [
            {
              "breakpoint": 1024,
              "settings": {
                "slidesToShow": 3,
                "slidesToScroll": 3,
                "infinite": true
              }
            },
            {
              "breakpoint": 780,
              "settings": {
                "slidesToShow": 2,
                "slidesToScroll": 2,
                "arrows": false,
                "speed": 200,
                "touchThreshold": 5,
                "centerMode": true,
                "infinite": true
              }
            },
            {
              "breakpoint": 428,
              "settings": {
                "slidesToShow": 1,
                "centerMode": true,
                "slidesToScroll": 1,
                "arrows": false,
                "speed": 200,
                "touchThreshold": 5,
                "infinite": true
              }
            }
          ]
        }
      }
    }
  }
</script>

<style lang="scss">
  .carousel-container {
    padding-left: 1em;
    padding-right: 1em;

    .slick-slide > div {
      outline: none;
    }

    .custom-arrow {
      z-index: 100;
    }

    .slick-next {
      width: auto;
      height: auto;

      &::before {
        content: '';
      }

      @include larger_desktop {
        right: 93%;
      }

      @include large_desktop {
        right: 90%;
      }

      @include desktop {
        right: 88%;
      }

      @include tablet {
        right: 81%;
      }

      @include mobile {
        right: 65%;
      }

      @include small-mobile {
        right: 63%;
      }
    }

    .slick-prev {
      width: auto;
      height: auto;

      &::before {
        content: '';
      }

      @include larger_desktop {
        left: 93%;
      }

      @include large_desktop {
        left: 90%;
      }

      @include desktop {
        left: 88%;
      }

      @include tablet {
        left: 81%;
      }

      @include mobile {
        left: 65%;
      }

      @include small-mobile {
        left: 63%;
      }
    }
  }
</style>
