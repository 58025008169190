<template>
  <router-link :exact="true" :active-class="'active'" :to="'/categories/' + item.slug" class="navbar-item">
    <p class="item-name">{{ item.name }}</p>
  </router-link>
</template>
<script>
  import { mapState, mapMutations } from 'vuex';
  import { TOGGLE_SIDEDRAW_MOBILE } from '../../../../store/mutation-types';

  export default {
    props: {
      item: {
        type: Object
      }
    },
    methods: {
      ...mapMutations('layout', [TOGGLE_SIDEDRAW_MOBILE])
    }
  }
</script>

<style lang="scss" scoped>
  .navbar-item {
    font-size: 16px;
    padding-top: 5px;
    padding-left: 4em;
    color: #000;
    &:hover {
      color: #000;
    }
  }

  .item-name {
    position: relative;

    display: inline-block;
  }

  .item-name:after {
    content: '';
    position: absolute;
    bottom: 0.5;
    left: 0;
    width: 100%;
    height: 2px;
    background: #000;
    display: none;
  }

  .item-name:hover:after {
    display: block;
  }
</style>
