<template>
  <div class="items">
    <div class="categories-item">
      <div><CakeBlack></CakeBlack></div>
      <span class="categories-text">Categories</span>
    </div>
    <NavigationItem v-for="item in items" :key="item.name" :item="item"></NavigationItem>
  </div>
</template>

<script>
  import NavigationItem from './NavigationItem/NavigationItem';
  import CakeBlack from '@/assets/images/CakeIconSidedraw.svg';

  export default {
    components: { NavigationItem, CakeBlack },
    props: {
      items: {
        type: Array
      }
    }
  }
</script>

<style lang="scss" scoped>
  .items {
    display: flex;
    flex-direction: column;
    border-bottom: solid 1px rgb(231, 231, 231);
    padding-bottom: 30px;
  }

  .categories-item {
    display: flex;
    padding: 30px 0 0 26px;
  }

  .categories-text {
    font-weight: bold;
    padding-left: 18px;
    width: 213px;
    height: 27px;
    font-size: 16px;
    color: #000000;
    text-decoration: none solid rgb(0, 0, 0);
    line-height: 27px;
    text-transform: uppercase;
  }
</style>
