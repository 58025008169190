import axios from 'axios';
import { ENTRY_POINT } from "../constants/URLs";

export default class Bakery {
  async getBakeryBySlug(slug) {
    try {
      const res = await axios.get(`${ENTRY_POINT}/bakeries.json`);
      let bakeries = [];
      for (const key in res.data) {
        bakeries.push({
          id: key,
          name: res.data[key].name,
          image: res.data[key].image,
          slug: res.data[key].slug,
          phone: res.data[key].phone,
          shortAddress: res.data[key].shortAddress,
          fullAddress: res.data[key].fullAddress,
          street: res.data[key].street,
          city: res.data[key].city
        });
      }
      let bakery = bakeries.filter(bakery => bakery.slug === slug)[0];
      return bakery;
    } catch (e) {
      console.log('Error while getting bakery information');
    }
  }
}
