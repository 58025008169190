<template>
  <transition name="slide-fade">
    <div
      class="dropdown-content"
      v-if="isDisplay"
    >
      <div class="close" @click="CLOSE_DESKTOP_DROPDOWN">
        <div><i class="fas fa-times"></i></div>
      </div>
      <template v-if="type === 'info'">
        <h5 class="title is-5">{{ 'Welcome' | capitalizeTextTransform }}</h5>
        <p>Welcome to {{ name | capitalizeTextTransform }}, where we offer you the latest and greatest cakes on the market. Check out our licensed characters from brands like Disney, Nickelodeon, DreamWorks, and Mattel, or peruse our themed and holiday options. The possibilities are endless!</p>

        <hr />

        <h5 class="title is-5">{{ 'Store hours' | capitalizeTextTransform }}</h5>
        <table>
          <tbody>
            <tr>
              <td><b class="day">Mon:</b></td>
              <td>8am - 6pm</td>
            </tr>
            <tr>
              <td><b class="day">Tues:</b></td>
              <td>8am - 6pm</td>
            </tr>
            <tr>
              <td><b class="day">Wed:</b></td>
              <td>8am - 6pm</td>
            </tr>
            <tr>
              <td><b class="day">Thurs:</b></td>
              <td>8am - 6pm</td>
            </tr>
            <tr>
              <td><b class="day">Fri:</b></td>
              <td>8am - 6pm</td>
            </tr>
            <tr>
              <td><b class="day">Sat:</b></td>
              <td>8am - 6pm</td>
            </tr>
            <tr>
              <td><b class="day">Sun:</b></td>
              <td>8am - 6pm</td>
            </tr>
          </tbody>
        </table>

        <hr />

        <h5 class="title is-5">{{ 'pickup / delivery options' | capitalizeTextTransform }}</h5>
        <p class="pickup-text">In-store Pickup, Curbside Pickup, Delivery available.</p>
        <p class="pickup-text">Delivery Radius: 20 miles</p>
      </template>

      <template v-else-if="type === 'map'">
        <h5 class="title is-5">{{ 'Find Us' }}</h5>
        <p>{{ street }},</p>
        <p>{{ city }}</p>
        <p>{{ phone }}</p>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2296.3865485593333!2d-93.39347959290083!3d45.20935758535042!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x52b317bc617b8651%3A0x6b8c8cae8bbaf3e6!2zQW5va2EsIE1pbm5lc290YSwgSG9hIEvhu7M!5e0!3m2!1svi!2s!4v1619413952223!5m2!1svi!2s" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
      </template>
    </div>
  </transition>
</template>

<script>
  import { mapState, mapMutations } from 'vuex';
  import { CLOSE_DESKTOP_DROPDOWN } from '../../store/mutation-types';

  export default {
    methods: {
      ...mapMutations('layout', [CLOSE_DESKTOP_DROPDOWN])
    },
    computed: {
      ...mapState({
        name: state => state.bakery.bakery.name,
        fullAddress: state => state.bakery.bakery.fullAddress,
        shortAddress: state => state.bakery.bakery.shortAddress,
        street: state => state.bakery.bakery.street,
        city: state => state.bakery.bakery.city,
        phone: state => state.bakery.bakery.phone,
        isDisplay: state => state.layout.dropdownDesktop.isDisplay,
        type: state => state.layout.dropdownDesktop.modalType,
      })
    }
  }
</script>

<style lang="scss" scoped>
  .dropdown-content {
    width: 150%;
    max-width: 22em;
    position: absolute;
    top: 100%;
    right: 2%;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
    z-index: 1;
    padding: 1rem;

    .title {
      margin-top: 10px;
      color: #676767;
    }

    .title.is-5 {
      font-size: 16px;
    }

    .pickup-text {
      font-size: 13px;
    }

    p {
      color: #676767;
    }

    .close {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 0.5em;
      cursor: pointer;
    }

    table {
      width: 100%;
      color: black;

      .day {
        width: 20px;
      }
    }
  }

  // transition
  .slide-fade-enter-active {
    transition: transform .3s linear, opacity .3s linear;
    -webkit-transition: transform .3s linear, opacity .3s linear;
    -moz-transition: transform .3s linear, opacity .3s linear;
    -o-transition: transform .3s linear, opacity .3s linear;
  }

  .slide-fade-leave-active {
    transition: transform .3s linear, opacity .3s linear;
    -webkit-transition: transform .3s linear, opacity .3s linear;
    -moz-transition: transform .3s linear, opacity .3s linear;
    -o-transition: transform .3s linear, opacity .3s linear;
  }

  .slide-fade-enter, .slide-fade-leave-to {
    transform: translateY(-50px);
    opacity: 0;
  }
</style>
