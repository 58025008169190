import { GET_CURRENT_BAKERY } from '../mutation-types';
import Bakery from '../../services/Bakery';

const state = () => ({
  bakery: {
    name: '',
    image: '',
    slug: '',
    id: '',
    phone: '',
    shortAddress: '',
    fullAddress: '',
    street: '',
    city: ''
  }
});

const getters = {};

const actions = {
  async getBakeryAsync({ commit }, slug) {
    const bakeryInstance = new Bakery();
    const bakery = await bakeryInstance.getBakeryBySlug(slug);
    commit({
      type: GET_CURRENT_BAKERY,
      bakery: bakery
    });
  }
}

const mutations = {
  [GET_CURRENT_BAKERY] (state, payload) {
    localStorage.setItem('slug', payload.bakery.slug);
    state.bakery = {
      ...state.bakery,
      ...payload.bakery
    };
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
