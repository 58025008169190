<template>
  <ul class="custom-breadcrumb">
    <li v-for="(item, index) in items" :key="index"
        :class="{ 'last-item': index !== items.length - 1 }"
        :style="{ paddingLeft: index === 0 ? '0em' : '0.3em' }">
      <router-link v-if="index !== items.length - 1" :exact="true" :active-class="'active'" :to="item.route">
        <span class="first-item">{{ item.text }}</span> /
      </router-link>
      <p class="text-underline" v-if="index == items.length - 1">{{ item.text }}</p>
    </li>
  </ul>
</template>

<script>
  export default {
    props: {
      items: {
        type: Array
      }
    }
  }
</script>

<style lang="scss" scoped>
  .custom-breadcrumb {
    display: flex;
    list-style-type: none;
    margin-left: 0em;

    @include small-screen {
      justify-content: center;
    }

    li {
      margin-top: 0;
      font-size: 13px;

      a {
        color: black;
      }
    }
  }

  .active {
    text-decoration: underline;
  }

  .text-underline, .first-item {
    position: relative;
    display: inline-block;
  }

  .text-underline:after, .first-item:after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 100%;
    height: 2px;
    background: #000;
  }

  .text-underline:after {
    display: block;
  }

  .first-item:after {
    display: none;
  }

  .first-item:hover:after {
    display: block;
  }
</style>
