<template>
  <transition name="slide-fade">
    <div class="modal-mobile" v-if="isDisplay" :style="{ top: calcTop }">
      <div class="scroll-modal">
        <div class="title-block">
          <div class="close" v-if="type === 'info'">
            <span class="title-text">Welcome</span>
          </div>
          <div class="close" v-else>
            <div class="icon-container"><MapPin></MapPin></div>
            <span class="title-text">Store Location</span>
          </div>
          <i class="fas fa-times" @click="HIDE_MODAL_MOBILE"></i>
        </div>
        <template v-if="type === 'info'">
          <div class="with-padding">
            <p>Welcome to {{ name | capitalizeTextTransform }}, where we offer you the latest and greatest cakes on the market. Check out our licensed characters from brands like Disney, Nickelodeon, DreamWorks, and Mattel, or peruse our themed and holiday options. The possibilities are endless!</p>

            <hr />

            <h5 class="title is-5">{{ 'Store hours' | capitalizeTextTransform }}</h5>
            <table>
              <tbody>
                <tr>
                  <td><span class="day">Mon:</span></td>
                  <td>8am - 6pm</td>
                </tr>
                <tr>
                  <td><span class="day">Tues:</span></td>
                  <td>8am - 6pm</td>
                </tr>
                <tr>
                  <td><span class="day">Wed:</span></td>
                  <td>8am - 6pm</td>
                </tr>
                <tr>
                  <td><span class="day">Thurs:</span></td>
                  <td>8am - 6pm</td>
                </tr>
                <tr>
                  <td><span class="day">Fri:</span></td>
                  <td>8am - 6pm</td>
                </tr>
                <tr>
                  <td><span class="day">Sat:</span></td>
                  <td>8am - 6pm</td>
                </tr>
                <tr>
                  <td><span class="day">Sun:</span></td>
                  <td>8am - 6pm</td>
                </tr>
              </tbody>
            </table>

            <hr />

            <h5 class="title is-5">{{ 'pickup / delivery options' | capitalizeTextTransform }}</h5>
            <p>In-store Pickup, Curbside Pickup, Delivery available. Delivery Radius: 20 miles</p>
          </div>
        </template>

        <template v-else-if="type === 'map'">
          <div class="with-padding">
            <p class="store-name">{{ name }}</p>
            <p>{{ street }},</p>
            <p>{{ city }}</p>
            <p>{{ phone }}</p>
            <a v-bind:href= "urlAddress" target="_blank">
              <p class="get-direction">Get Directions</p>
            </a>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2296.3865485593333!2d-93.39347959290083!3d45.20935758535042!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x52b317bc617b8651%3A0x6b8c8cae8bbaf3e6!2zQW5va2EsIE1pbm5lc290YSwgSG9hIEvhu7M!5e0!3m2!1svi!2s!4v1619413952223!5m2!1svi!2s" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
          </div>
        </template>
      </div>
    </div>
  </transition>
</template>

<script>
  import { mapState, mapMutations } from 'vuex';
  import { HIDE_MODAL_MOBILE } from '@/store/mutation-types';
  import MapPin from '@/assets/images/MapPin.svg';

  export default {
    components: { MapPin },
    methods: {
      ...mapMutations('layout', [HIDE_MODAL_MOBILE])
    },
    computed: {
      ...mapState({
        name: state => state.bakery.bakery.name,
        fullAddress: state => state.bakery.bakery.fullAddress,
        phone: state => state.bakery.bakery.phone,
        street: state => state.bakery.bakery.street,
        city: state => state.bakery.bakery.city,
        isDisplay: state => state.layout.modalMobile.isDisplay,
        type: state => state.layout.modalMobile.modalType,
        urlAddress: state => "https://maps.google.com/maps?saddr=&daddr=" + encodeURI(state.bakery.bakery.fullAddress)
      }),
      calcTop() {
        return '5em';
      }
    },
  }
</script>

<style lang="scss" scoped>
  .modal-mobile {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    padding: 1rem;
    background-color: white;
    color: #000;

    @include large-screen {
      display: none;
    }

    .title {
      margin-top: 10px;
    }

    .title.is-5 {
      font-size: 16px;
      margin-bottom: 6px;
      font-weight: bold;
      color: #000;
    }

    .close {
      display: flex;
      margin-bottom: 0.5em;
    }

    table {
      width: 100%;

      .day {
        width: 20px;
        font-weight: 600;
      }
    }

    .with-padding {
      padding-left: 2em;
      padding-right: 2em;
      padding-top: 2em;
    }

    .scroll-modal {
      overflow-y: auto;
      height: 100%;
    }

    table, td {
      border: none;
    }
  }

  .get-direction {
    padding-top: 8px;
    padding-bottom: 8px;
    color: #000000;
    text-decoration: underline;
    font-size: 16px;

    &:hover {
      text-decoration: none;
    }
  }

  p {
    margin-bottom: 0 !important;
  }

  .title-block {
    display: flex;
    border-bottom: solid 1px rgb(231, 231, 231);
    justify-content: space-between;
    padding: 0 0 15px 8px;
  }

  .title-text {
    width: 213px;
    height: 27px;
    font-weight: bold;
    font-size: 16px;
    color: #000000;
    text-decoration: none solid rgb(0, 0, 0);
    line-height: 27px;
    text-transform: uppercase;
    padding-left: 4px;
  }

  .store-name {
    font-weight: 600;
    color: #000000;
  }

  .icon-container {
    padding-top: 0.2em;
  }

  // transition
  .slide-fade-enter-active {
    transition: transform .3s linear;
    -webkit-transition: transform .3s linear;
    -moz-transition: transform .3s linear;
    -o-transition: transform .3s linear;
  }
  .slide-fade-leave-active {
    transition: transform .8s linear;
    -webkit-transition: transform .4s linear;
    -moz-transition: transform .4s linear;
    -o-transition: transform .4s linear;
  }
  .slide-fade-enter, .slide-fade-leave-to {
    transform: translateX(-100%);
  }
</style>
