<template>
  <Backdrop :isShow="isDisplay" @backdrop-clicked="TOGGLE_SIDEDRAW_MOBILE">
    <div class="sidedraw">
      <div class="logo home-block">
        <div class="close home-icon">
          <router-link :to="'/bakeries/' + slug">
            <HomeIcon></HomeIcon><span class="home-text">Home</span>
          </router-link>
        </div>
        <div class="mr-26" @click="TOGGLE_SIDEDRAW_MOBILE"><CancelIcon></CancelIcon></div>
      </div>
      <NavigationItems :items="categories"></NavigationItems>
      <LocationItem></LocationItem>
    </div>
  </Backdrop>
</template>

<script>
  import { mapState, mapMutations } from 'vuex';
  import { TOGGLE_SIDEDRAW_MOBILE } from '../../store/mutation-types';
  import Backdrop from '../UI/Backdrop/Backdrop.vue';
  import NavigationItems from './NavigationItems/NavigationItems';
  import CancelIcon from '@/assets/images/cancel.svg';
  import HomeIcon from '@/assets/images/Home.svg';
  import LocationItem from './LocationItem/LocationItem'

  export default {
    components: { Backdrop, NavigationItems, CancelIcon, HomeIcon, LocationItem },
    data() {
      return {
        styles: ['Cupcakes', 'Cakes', 'PhotoCake®', '1/4 sheet'],
      }
    },
    methods: {
      ...mapMutations('layout', [TOGGLE_SIDEDRAW_MOBILE])
    },
    computed: {
      ...mapState({
        categories: state => state.categories.categories,
        slug: state => state.bakery.bakery.slug,
        image: state => state.bakery.bakery.image,
        isDisplay: state => state.layout.sidedraw.isDisplay
      })
    }
  }
</script>

<style lang="scss" scoped>
  .sidedraw {
    width: 100%;
    position: absolute;
    overflow: auto;
    z-index: 160;
    height: 100vh;
    background-color: white;
    padding-top: 0.5em;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;

    @include large-screen {
      display: none;
    }

    .logo {
      display: flex;
      flex-direction: row;
      align-items: center;

      img, .close {
        margin-left: 26px;
      }
    }
  }

  .home-text {
    width: 72px;
    height: 22px;
    margin-left: 16px;
    font-size: 16px;
    color: #000000;
    text-decoration: none solid rgb(0, 0, 0);
  }

  .home-block {
    border-bottom: solid 1px rgb(231, 231, 231);
    justify-content: space-between;
    padding-bottom: 16px;
  }

  .home-icon {
    display: flex;
  }

  .mr-26 {
    margin-right: 26px;
  }
</style>
