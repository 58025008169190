<template>
  <li class="navigation-item">
    <router-link :to="'/categories/' + item.slug" exact :active-class="'active-link'">
      <span class="item-name">{{ item.name }}</span>
    </router-link>
  </li>
</template>

<script>
  export default {
    props: {
      item: Object,
      default: {
        name: '',
        slug: ''
      }
    }
  }
</script>

<style lang="scss" scoped>
  li > a {
    color: black;
    text-decoration: none;
    font-weight: 400;

    @include larger_desktop {
      margin-right: 1.5em;
    }

    @include large_desktop {
      margin-right: 1.5em;
    }

    @include desktop {
       margin-right: 2.5em;
    }
  }

  .item-name {
    position: relative;
  }

  .item-name:after {
    content: '';
    position: absolute;
    bottom: 0.5;
    left: 0;
    width: 105%;
    height: 2px;
    background: #000;
    display: none;
  }

  .item-name:hover:after {
    display: block;
  }
</style>
