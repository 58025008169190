import axios from 'axios';
import { GET_ALL_CATEGORIES } from '../mutation-types'
import { ENTRY_POINT } from '../../constants/URLs'
import Product from "../../services/Product";
const state = () => ({
  categories: []
});

const getters = {
  getCategories: state => {
    return state.categories;
  }
};

const actions = {
  async getAllCategoriesAsync({ commit }) {
    const productInstance = new Product;
    const res = await axios.get(`${ENTRY_POINT}/categories.json`);
    let categories = [];
    for (const key in res.data) {
      if (res.data[key].parent === "") {
        const categoryHasCakes = await productInstance.getFirstCakeOfEachSubcategory(res.data[key].slug, 1, 1);
        if(categoryHasCakes.totalProducts > 0) {
          categories.push({
            id: key,
            name: res.data[key].name,
            slug: res.data[key].slug
          });
        }
      }
    }
    commit({
      type: GET_ALL_CATEGORIES,
      categories: categories
    });
  }
}

const mutations = {
  [GET_ALL_CATEGORIES] (state, payload) {
    state.categories = payload.categories;
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
