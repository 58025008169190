<template>
  <div
    class="product-container"
    @click="onClickedCardHandler"
  >
    <div class="product-item" :class="{ 'dropShadowMobile': isDisplayDropShadowMobile }">
      <div class="product-image">
        <img :src="image" alt="logo" />
      </div>
      <p v-if="itemType === 'subcategory'" class="product-name product-text">{{ subcategory | categoryNameTransform }}</p>
      <p v-else-if="itemType === 'product'" class="product-name product-text">{{ name }}</p>
      <p
        v-if="itemType === 'product'"
        class="product-type product-text">
          <span class="product-line">{{ cakeType === 'PhotoCakes' ? 'PhotoCake®' : cakeType }}</span>
          <span v-if="sold">
            <span class="product-divide"> / </span>
            <span class="product-price">${{ sold | numberWithDot }}</span>
          </span>
      </p>
      <CakeIndicators
        v-if="itemType === 'product'"
        :addable-photo-cake="addablePhotoCake"
        :is-cake-size-format-available="isCakeSizeFormatAvailable"
        :is-cupcake-size-format-available="isCupcakeSizeFormatAvailable"
      ></CakeIndicators>
    </div>
  </div>
</template>

<script>
  import { mapState } from "vuex";
  import CakeIndicators from './CakeIndicators/CakeIndicators';

  export default {
    components: { CakeIndicators },
    props: {
      image: {
        type: String
      },
      name: {
        type: String
      },
      slug: {
        type: String
      },
      cakeType: {
        type: String
      },
      category: {
        type: String
      },
      categorySlug: {
        type: String
      },
      subcategory: {
        type: String
      },
      subcategorySlug: {
        type: String
      },
      itemType: {
        type: String,
        default: 'subcategory'
      },
      addablePhotoCake: {
        type: Boolean,
        default: true
      },
      isCakeSizeFormatAvailable: {
        type: Boolean,
        default: true
      },
      isCupcakeSizeFormatAvailable: {
        type: Boolean,
        default: true
      },
      sold: {
        type: Number
      },
      id: {},
      searchMagnitude: {}
    },
    data () {
      return {
        isDisplayDropShadowMobile: false
      }
    },
    filters: {
      numberWithDot: function (value) {
        return parseFloat(value).toFixed(2);
      }
    },
    computed: {
      route: function() {
        if (this.itemType === 'subcategory') {
          return `/categories/${this.categorySlug}/subcategories/${this.subcategorySlug}`
        } else {
          return `/cakes/${this.slug}`;
        }
      },
      ...mapState({
        isMobileScreen: state => state.layout.isMobileScreen
      })
    },
    methods: {
      onClickedCardHandler() {
        if (this.isMobileScreen) {
          this.isDisplayDropShadowMobile = true;
          setTimeout(() => {
            this.$router.push(this.route);
          }, 1000);
        } else {
          this.$router.push(this.route);
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .product-container {
    flex-basis: 25%;
    padding: 1em;
    margin-bottom: 1em;

    &:hover {
      cursor: pointer;
    }

    @include tablet {
      flex-basis: 50%;
    }

    @include mobile {
      flex-basis: 100%;
    }

    @include small-mobile {
      flex-basis: 100%;
    }

    .dropShadowMobile {
      @include small-screen {
        box-shadow: 4px 4px #f4f0ed;
      }
    }

    .product-item {
      border: 1px solid #f4f0ed;
      padding-bottom: 2em;
      min-height: 10em;

      @include large-screen {
        &:hover {
          box-shadow: 2px 2px #f4f0ed;
        }
      }

      .product-image {
        display: flex;
        justify-content: center;
        align-content: center;
        flex-direction: column;

        img {
					width: 70%;

					@include small-mobile {
          	width: 100%;
					}

					@include mobile {
						width: 80%;
					}

					@include tablet {
						width: 90%;
					}

          height: auto;
          align-self: center;
        }
      }

      .product-text {
        text-align: center;
        color: black;
        font-size: 14px;
      }

      .product-name {
				margin-left: 0.5em;
				margin-right: 0.5em;
				margin-bottom: 0.4em;
        font-size: 14px;

				@include large-screen {
					height: 2em;
					margin-bottom: 1em;
				}

        @include small-mobile {
          margin-top: 0;
        }
      }

      .product-type {
        margin-top: 0;
      }

      .product-line {
        position: relative;
      }

      .product-line:after {
        content: '';
        position: absolute;
        bottom: 0.5;
        left: 0;
        width: 105%;
        height: 2px;
        background: #000;
        display: none;
      }

      .product-line:hover:after {
        display: block;
      }
    }
  }
</style>
