import { TOGGLE_MOBILE_SEARCH_INPUT, HIDE_MOBILE_SEARCH_INPUT, SHOW_MOBILE_SEARCH_INPUT, TOGGLE_SIDEDRAW_MOBILE, HIDE_SIDEDRAW_MOBILE, TOGGLE_DESKTOP_DROPDOWN, CLOSE_DESKTOP_DROPDOWN, SHOW_DESKTOP_DROPDOWN, SHOW_SEARCH_BAR, HIDE_SEARCH_BAR, TOGGLE_SEARCH_BAR, SHOW_BAKERY_INFO_ICONS, HIDE_BAKERY_INFO_ICONS, TOGGLE_BAKERY_INFO_ICONS , SHOW_MODAL_MOBILE, HIDE_MODAL_MOBILE, TOGGLE_MODAL_MOBILE, SCROLLED_TOP, SCROLLED_DOWN, SET_IS_SMALL_SCREEN_SIZE } from '../mutation-types';

const htmlEl = document.querySelector("html");
const state = () => ({
  dropdownDesktop: {
    isDisplay: false,
    modalType: 'info'
  },
  dropdownMobile: {
    isDisplay: false,
    modalType: ''
  },
  mobileSearchInput: {
    isDisplay: true
  },
  sidedraw: {
    isDisplay: false
  },
  searchIconDesktop: {
    isDisplay: false
  },
  searchBarDesktop: {
    isDisplay: true
  },
  bakeryInfoIcons: {
    isDisplay: false
  },
  modalMobile: {
    isDisplay: false,
    modalType: 'info'
  },
  isScrolledDown: false,
  isMobileScreen: false
});

const getters = {};

const actions = {};

const mutations = {
  [TOGGLE_MOBILE_SEARCH_INPUT] (state) {
    state.mobileSearchInput.isDisplay = !state.mobileSearchInput.isDisplay;
  },
  [HIDE_MOBILE_SEARCH_INPUT] (state) {
    state.mobileSearchInput.isDisplay = false;
  },
  [SHOW_MOBILE_SEARCH_INPUT] (state) {
    state.mobileSearchInput.isDisplay = true;
  },
  [TOGGLE_SIDEDRAW_MOBILE] (state) {
    state.sidedraw.isDisplay = !state.sidedraw.isDisplay;
    // Fix sidedraw.isDisplay -- hidden
    if (state.sidedraw.isDisplay) {
      htmlEl.style.overflowY = "scroll";
    } else {
      htmlEl.style.overflowY = "scroll";
    }
  },
  [HIDE_SIDEDRAW_MOBILE] (state) {
    state.sidedraw.isDisplay = false;
  },
  [TOGGLE_DESKTOP_DROPDOWN] (state, { modalType }) {
    if (state.dropdownDesktop.isDisplay && modalType !== state.dropdownDesktop.modalType) {
      state.dropdownDesktop.modalType = modalType;
    } else if (state.dropdownDesktop.isDisplay && modalType === state.dropdownDesktop.modalType) {
      state.dropdownDesktop.isDisplay = !state.dropdownDesktop.isDisplay;
    } else if (!state.dropdownDesktop.isDisplay) {
      state.dropdownDesktop.isDisplay = true;
      state.dropdownDesktop.modalType = modalType;
    }
  },
  [SHOW_DESKTOP_DROPDOWN] (state, { modalType }) {
    state.dropdownDesktop.isDisplay = true;
    state.dropdownDesktop.modalType = modalType;
  },
  [CLOSE_DESKTOP_DROPDOWN] (state) {
    state.dropdownDesktop.isDisplay = false;
  },
  [SHOW_MODAL_MOBILE] (state, { modalType }) {
    state.modalMobile.isDisplay = true;
    htmlEl.style.overflowY = "hidden";
    state.modalMobile.modalType = modalType;
  },
  [TOGGLE_MODAL_MOBILE] (state, { modalType }) {
    if (state.modalMobile.isDisplay && modalType !== state.modalMobile.modalType) {
      state.modalMobile.modalType = modalType;
    } else if (state.modalMobile.isDisplay && modalType === state.modalMobile.modalType) {
      state.modalMobile.isDisplay = false;
    } else if (!state.modalMobile.isDisplay) {
      state.modalMobile.isDisplay = !state.modalMobile.isDisplay;
      state.modalMobile.modalType = modalType;
    }
    if (state.modalMobile.isDisplay) {
      htmlEl.style.overflowY = "hidden";
    } else {
      htmlEl.style.overflowY = "scroll";
    }
  },
  [HIDE_MODAL_MOBILE] (state) {
    state.modalMobile.isDisplay = false;
    htmlEl.style.overflowY = "scroll";
  },
  [SHOW_SEARCH_BAR] (state) {
    state.searchBarDesktop.isDisplay = true;
  },
  [HIDE_SEARCH_BAR] (state) {
    state.searchBarDesktop.isDisplay = false;
  },
  [TOGGLE_SEARCH_BAR] (state) {
    state.searchBarDesktop.isDisplay = !state.searchBarDesktop.isDisplay;
  },
  [SHOW_BAKERY_INFO_ICONS] (state) {
    state.bakeryInfoIcons.isDisplay = true;
  },
  [HIDE_BAKERY_INFO_ICONS] (state) {
    state.bakeryInfoIcons.isDisplay = false;
  },
  [TOGGLE_BAKERY_INFO_ICONS] (state) {
    state.bakeryInfoIcons.isDisplay = !state.bakeryInfoIcons.isDisplay;
  },
  [SCROLLED_TOP] (state) {
    state.isScrolledDown = false;
  },
  [SCROLLED_DOWN] (state) {
    state.isScrolledDown = true;
  },
  [SET_IS_SMALL_SCREEN_SIZE] (state, { isMobileScreenSize }) {
    state.isMobileScreen = isMobileScreenSize;
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
