<template>
  <div class="style-selection">
    <span class="style">Style</span>
    <label v-for="(item, index) in items" :key="index" class="checkbox">
      <input
          :checked="item.value === selectedValue"
          :value="item.value" type="checkbox"
          @click="clickCheckboxHandler(item.value)"
      >{{ ' ' + item.text }}
    </label>
  </div>
</template>

<script>
export default {
  props: {
    selectedValue: {
      type: String,
      default: 'All'
    }
  },
  data() {
    return {
      items: [
        {
          text: 'Cakes',
          value: 'Cakes',
          checked: false
        },
        {
          text: 'PhotoCake®',
          value: 'PhotoCakes',
          checked: false
        },
        {
          text: 'Cupcakes',
          value: 'Cupcakes',
          checked: false
        },
        {
          text: '1/4 sheet',
          value: '1/4 sheet',
          checked: false
        }
      ]
    }
  },
  created() {
    this.items.forEach(item => {
      if (item.value === this.selectedValue) {
        item.checked = true;
      }
    });
  },
  methods: {
    clickCheckboxHandler: function (value) {
      let unCheckedAll = false;
      this.items.forEach(item => {
        if (item.value !== value) {
          item.checked = false;
        } else if (item.value === value) {
          item.checked = !item.checked;
          if (!item.checked) unCheckedAll = true;
        }
      });
      if (unCheckedAll) {
        this.$emit('select-cake-type', 'All');
      } else {
        this.$emit('select-cake-type', value);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.style-selection {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #d8d8d8;
  margin-bottom: 2em;

  @include small-screen {
    padding-top: 4em;
    margin-bottom: 3.5em;
    align-items: flex-start;
    border-bottom: none;
  }

  .style {
    color: black;
    padding-bottom: 0.5em;
    font-size: 20px;
    font-weight: 550;
  }

  label {
    padding-bottom: 1em;
    color: black;
  }
}
</style>
