<template>
	<div>
		<h1>This is the page for data preparation</h1>
		<button @click="seedData">Seed Data Now</button>
	</div>
</template>

<script>
import Product from "../../services/Product";
import axios from 'axios';
import { ENTRY_POINT, SALSIFY_END_POINT } from "@/constants/URLs";
import { getRandomInt, getRandomBoolean, getIdFromProductSlug, generateDummyDescription } from '@/utils/utils';

export default {
	name: "DataSeeding",
	methods: {
		seedData: async function() {
			const productIns = new Product();
			const allCakes = await productIns.getAllProducts();
			let cakes = [];
			for (let key in allCakes) {
				if (allCakes.hasOwnProperty(key)) {
					cakes.push({
						id: key,
						name: allCakes[key].name,
						slug: allCakes[key].slug,
						category: allCakes[key].category.toLowerCase(),
						categorySlug: allCakes[key].categorySlug.toLowerCase(),
						subCategory: allCakes[key].subCategory.toLowerCase(),
						subCategorySlug: allCakes[key].subCategorySlug.toLowerCase(),
						image: allCakes[key].image,
						type: allCakes[key].type === 'PhotoCakes' ? 'PhotoCake®' : allCakes[key].type
					});
				}
			}
			const cakesUpdatePromiseArr = cakes.map(cake => {
				const id = cake.id;
				delete cake["id"];
				const body = {
					[id]: {
						...cake,
						type: cake.type === 'PhotoCake®' ? 'PhotoCakes' : cake.type,
						addablePhotoCake: cake.type === 'PhotoCake®' ? true : getRandomBoolean(),
						isCakeSizeFormatAvailable: cake.type === 'Cakes' ? true : getRandomBoolean(),
						isCupcakeSizeFormatAvailable: cake.type === 'Cupcakes' ? true : getRandomBoolean(),
						sold: getRandomInt(),
						chokingHazardWarning: getRandomBoolean(),
						id: getIdFromProductSlug(cake.slug),
						description: generateDummyDescription()
					}
				}
				return axios.patch(`${ENTRY_POINT}/cakes.json`, body);
			});
			Promise.all(cakesUpdatePromiseArr)
				.then(res => console.log(res));
		}
	}
}
</script>
