<template>
  <div class="bottom-line" @click="HIDE_SEARCH_BAR"></div>
</template>

<script>
  import { mapMutations } from 'vuex';
  import { HIDE_SEARCH_BAR } from '../../../../../store/mutation-types';

  export default {
    methods: {
      ...mapMutations('layout', [HIDE_SEARCH_BAR])
    }
  }
</script>

<style lang="scss" scoped>
  .bottom-line {
    position: absolute;
    top: 4.5em;
    left: 45%;
    background-color: #929790;
    height: 0.2em;
    width: 10%;

    &:hover {
      cursor: pointer;
    }
  }
</style>
