<template>
	<transition name="fade">
		<div
			v-if="isDisplay"
			class="sort-and-filter-cakes-mobile"
		>
			<div class="filter-header">
				<h3>Filters</h3>
				<div class="close-icon" @click="$emit('close-sort-and-filter-modal')">
					<CloseSortAndFilterMobile></CloseSortAndFilterMobile>
				</div>
			</div>

			<Select
				:selectedSortType="selectedSortType"
				@on-select-cake-type="onSelectedSortTypeHandler"
			>
			</Select>

			<StyleSelection
				:selectedValue="localSelectedCakeType"
				@select-cake-type="onSelectCakeTypeHandler"
			>
			</StyleSelection>

			<Button @on-button-click="submitSortAndFilterModal">Done</Button>
		</div>
	</transition>
</template>

<script>
import CloseSortAndFilterMobile from '@/assets/images/CloseSortAndFilterMobile.svg';
import Select from "./Selects/Select";
import StyleSelection from '../../StyleSelection/StyleSelection';
import Button from '../Button/Button';

export default {
	name: "SortAndFilterCakesMobile",
	components: { CloseSortAndFilterMobile, Select, StyleSelection, Button },
	props: {
		isDisplay: {
			type: Boolean,
			default: false
		},
		selectedSortType: {
			type: String,
			default: 'az'
		},
		selectedCakeType: {
			type: String,
			default: 'All'
		}
	},
	data() {
		return {
			localSelectedSortType: this.selectedSortType,
			localSelectedCakeType: this.selectedCakeType
		}
	},
	watch: {
		selectedSortType(to) {
			this.localSelectedSortType = to;
		},
		selectedCakeType(to) {
			this.localSelectedCakeType = to;
		}
	},
	methods: {
		onSelectedSortTypeHandler: function(sortTypeValue) {
			this.localSelectedSortType = sortTypeValue;
		},
		onSelectCakeTypeHandler: function(cakeTypeValue) {
			this.localSelectedCakeType = cakeTypeValue;
		},
		submitSortAndFilterModal: function() {
			this.$emit('on-submitted-modal-filter-mobile', this.localSelectedSortType, this.localSelectedCakeType);
		}
	}
}
</script>

<style lang="scss" scoped>
	.sort-and-filter-cakes-mobile {
		position: fixed;
		background-color: white;
		width: 100%;
		height: 100%;
		max-width: inherit;
		padding-left: 1em;
		padding-right: 1em;

		@include large-screen {
			display: none;
		}

		.filter-header {
			display: flex;
			padding-top: 0.7em;
			justify-content: space-between;
			border-bottom: 1px solid #dddddd;

			h3 {
				color: black;
			}
		}
	}

	// transition
	.fade-enter-active {
		transition: transform .3s linear, opacity .5s linear;
		-webkit-transition: transform .3s linear, opacity .5s linear;
		-moz-transition: transform .3s linear, opacity .5s linear;
		-o-transition: transform .3s linear, opacity .5s linear;
	}

	.fade-leave-active {
		transition: transform .3s linear, opacity .5s linear;
		-webkit-transition: transform .3s linear, opacity .5s linear;
		-moz-transition: transform .3s linear, opacity .5s linear;
		-o-transition: transform .3s linear, opacity .5s linear;
	}

	.fade-enter, .fade-leave-to {
		transform: translateY(-20px);
		opacity: 0;
	}
</style>
