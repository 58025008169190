<template>
	<div class="button-container">
		<button
			class="custom-button"
			:class="[btnType]"
			@click="onClickedHandler"
		>
			<slot></slot>
		</button>
	</div>
</template>

<script>
	export default {
		props: {
			btnType: {
				type: String,
				default: 'secondary'
			},
			isRound: {
				type: Boolean,
				default: false
			}
		},
		methods: {
			onClickedHandler: function() {
				this.$emit('on-button-click');
			}
		}
	}
</script>

<style lang="scss" scoped>
	.button-container {

		.custom-button {
			color: #000000;
			border-radius: 4px;
			width: 100%;
			height: 2.8em;
			display: flex;
			justify-content: center;
			align-items: center;
			font-weight: 600;
		}

		.primary {
			background-color: #929790;
			border: none;
		}

		.secondary {
			border: 2px solid #929790;
			background-color: white;		}

		is-round {
			border-radius: 60%;
		}
	}
</style>
