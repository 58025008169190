import '@/assets/main.scss';
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index'
import './filters/filters'
import Fragment from 'vue-fragment';

Vue.config.productionTip = false;
Vue.use(Fragment.Plugin);

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
