<template>
	<div class="sort-by">
		<h5>Sort By</h5>
		<div class="select" @change="onChangeSortType($event)">
			<select :value="selectedSortType">
				<option
					v-for="option in options"
					:key="option.value"
					:value="option.value"
				>
						{{ option.text }}
				</option>
			</select>
		</div>
	</div>
</template>

<script>
export default {
	name: "Select",
	props: {
		selectedSortType: {
			type: String,
			require: true
		}
	},
	data() {
		return {
			options: [
        {
          text: 'Search Magnitude',
          value: 'search-magnitude'
        },
        {
					text: 'Best Sellers',
					value: 'best-sellers'
				},
				{
					text: 'A - Z',
					value: 'az'
				},
				{
					text: 'Z - A',
					value: 'za'
				}
			]
		}
	},
	methods: {
		onChangeSortType: function(e) {
			this.$emit('on-select-cake-type', e.target.value);
		}
	}
}
</script>

<style lang="scss" scoped>
	.sort-by {
		padding-top: 0.7em;
		display: flex;
		flex-direction: column;

		h5 {
			color: black;
		}

		label {
			font-weight: 600;
		}

		.select {
			padding-top: 0.7em;

			select {
				width: 100%;
				max-width: inherit;
				border-radius: 8px;
			}
		}

		.select:not(.is-multiple):not(.is-loading)::after {
			top: 2em;
			border-color: #929790;
		}
	}
</style>
