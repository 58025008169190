<template>
  <div class="cake-item-container">
    <div class="cake-item">
      <div class="cake-item-image">
        <figure>
          <img :src="image" alt="Placeholder image">
        </figure>
      </div>
      <div class="cake-item-content">
        <p class="is-6">{{ name }}</p>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'CakeItem',
    props: {
      image: {
        type: String
      },
      name: {
        type: String
      }
    }
  }
</script>

<style lang="scss" scoped>
  .cake-item-container {
    margin-top: 2em;
    margin-bottom: 2em;
    background-color: #F4F4F4;

    .cake-item {
      background-color: white;
      margin: auto;
      width: 90%;

      @include small-screen {
        width: 98%;
      }
    }

    .cake-item-content {
      height: 3em;
      padding-bottom: 3em;
      background-color: white;

      p {
        text-align: center;
        color: black;
      }
    }

    .is-6 {
      font-size: 14px;
    }

    &:focus {
      outline: none;
    }
  }
</style>
