<template>
  <transition name="slide-fade">
    <div v-if="isDisplay" class="bakery-information-bar">
      <div class="info-item" @click="TOGGLE_MODAL_MOBILE({ modalType: 'info' })">
        <div class="icon-container">
          <InfoIcon></InfoIcon>
        </div>
        <p class="icon-info">Info</p>
      </div>

      <div class="map-item" @click="TOGGLE_MODAL_MOBILE({ modalType: 'map' })">
        <div class="icon-container">
          <MapPinIcon></MapPinIcon>
        </div>
        <p class="icon-info">Map</p>
      </div>
    </div>
  </transition>
</template>

<script>
  import { mapMutations } from 'vuex';
  import { SHOW_MODAL_MOBILE, TOGGLE_MODAL_MOBILE } from '../../../../store/mutation-types';
  import InfoIcon from '@/assets/images/InfoIcon.svg';
  import MapPinIcon from '@/assets/images/MapPin.svg';

  export default {
    components: { InfoIcon, MapPinIcon },
    props: {
      isDisplay: {
        type: Boolean,
        default: true
      },
      shortAddress: {
        type: String,
        default: 'Anoka, MN'
      }
    },
    methods: {
      ...mapMutations('layout', [SHOW_MODAL_MOBILE, TOGGLE_MODAL_MOBILE])
    }
  }
</script>

<style lang="scss" scoped>
  .bakery-information-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 2em;

    .info-item {
      display: flex;
      padding-left: 1em;
      align-items: center;

      .icon-container {
        padding-top: 0.3em;
      }

      p {
        font-weight: normal;
        margin-left: 0.3em;
      }
    }

    .map-item {
      display: flex;
      padding-right: 1em;
      align-items: center;

      .icon-container {
        padding-top: 0.2em;
      }

      p {
        font-weight: normal;
        padding-left: 0.1em;
      }
    }
  }

  .icon-info {
    position: relative;
    display: inline-block;
  }

  .icon-info:after {
    content: '';
    position: absolute;
    bottom: 0.5;
    left: 0;
    width: 100%;
    height: 2px;
    background: #000;
    display: none;
  }

  .icon-info:hover:after {
    display: block;
  }

  // transition
  .slide-fade-enter-active {
    transition: all 0.5s ease;
  }
  .slide-fade-leave-active {
    transition: all 0.5s ease;
  }
  .slide-fade-enter, .slide-fade-leave-to {
    transform: translateY(-50px);
    opacity: 0;
  }
</style>
