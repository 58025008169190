import axios from 'axios';
import {ENTRY_POINT} from "../constants/URLs";
const allCategories = axios(`${ENTRY_POINT}/categories.json`);

export default class Category {
  async getSubcategoriesByCategorySlug(categorySlug) {
    try {
      const res = await allCategories;

      const result = [];
      for (const key in res.data) {
        if (res.data.hasOwnProperty(key) && res.data[key].parent.toLowerCase() === categorySlug) {
          result.push({
            id: key,
            name: res.data[key].name,
            slug: res.data[key].slug.toLowerCase(),
            parent: res.data[key].parent
          });
        }
      }

      return result;
    } catch (e) {
      console.log(e)
      console.log('Error while trying to get subcategory')
    }
  }

  async getCategoryBySlug(slug) {
    try {
      const res = await allCategories;
      let result = {};
      for (const key in res.data) {
        if (res.data.hasOwnProperty(key) && res.data[key].slug.toLowerCase() === slug.toLowerCase()) {
          result.id = key;
          result.name = res.data[key].name;
          result.slug = res.data[key].slug;
          result.parent = res.data[key].parent;
        }
      }
      return result;
    } catch (e) {
      console.log(e)
      console.log('Error while trying to get subcategory')
    }
  }
}
