<template>
  <Fragment>
    <Title :headerType="5">{{ 'Featured' | capitalizeTextTransform }}</Title>
    <Carousel :cakes="firstCakeOfEachCategory"></Carousel>
    <Title :headerType="5">{{ 'Find the right size' | capitalizeTextTransform }}</Title>
    <div class="filter-button-group">
      <Pills :items="sizes" @pill-clicked-handler="cakeSizeFilterClicked"></Pills>
    </div>
    <Carousel :backgroundColor="'#F4F0ED80'" :carouselType="'cakeItems'" :cakes="filterBySize"></Carousel>
    <Title :headerType="5">{{ 'Browse categories' | capitalizeTextTransform }}</Title>
<!--    <CategoryItems :cakes="firstCakeOfEachCategory" />-->
    <Carousel :cakes="firstCakeOfEachCategory"></Carousel>
  </Fragment>
</template>

<script>
  import { mapActions } from 'vuex';
  import { Fragment } from 'vue-fragment';

  import Product from '../../services/Product';
  import Carousel from '../../components/UI/Carousel/Carousel';
  import CategoryItems from '../../components/CategoryItems/CategoryItems';
  import Title from '../../components/UI/Title/Title';
  import Pills from '../../components/UI/Pills/Pills';
  import Category from "../../services/Category";

  export default {
    name: 'LandingPage',
    components: { Carousel, CategoryItems, Fragment, Title, Pills },
    data() {
      return {
        selectedSize: 'All',
        sizes: ['All', 'Cupcakes', 'Cakes', 'PhotoCake®', '1/4 sheet'],
        cakes: [],
        categories: []
      }
    },
    created: async function() {
      this.$router?.history?.current?.params?.slug && await this.getBakery(this.$router.history.current.params.slug);
      const product = new Product();
      const data = await product.getAllProducts();

      for (let key in data) {
        this.cakes.push({
          id: key,
          name: data[key].name,
          slug: data[key].slug,
          category: data[key].category.toLowerCase(),
          categorySlug: data[key].categorySlug.toLowerCase(),
          subCategory: data[key].subCategory.toLowerCase(),
          subCategorySlug: data[key].subCategorySlug.toLowerCase(),
          image: data[key].image,
          type: data[key].type === 'PhotoCakes' ? 'PhotoCake®' : data[key].type
        });
      }
      this.cakes.sort((a, b) => (a.category > b.category) ? 1 : ((b.category > a.category) ? -1 : 0));
    },
    methods: {
      ...mapActions({
        getBakery: 'bakery/getBakeryAsync'
      }),
      cakeSizeFilterClicked: function(size) {
        this.selectedSize = size;
      }
    },
    computed: {
      firstCakeOfEachCategory() {
        let cakeItems = [...this.cakes];
        let i = 0;
        while (i < cakeItems.length - 1) {
          const cakeItemCategories = cakeItems[i].category.split(',').filter(value => value);
          const cakeItemSubCategories = cakeItems[i].subCategory.split(',').filter(value => value);

          if (cakeItems[i].category === cakeItems[i + 1].category || cakeItemCategories.length > 1 || cakeItemSubCategories > 1) {
            if(cakeItemCategories.length > 1 || cakeItemSubCategories > 1) {
              cakeItems.splice(i, 1);
            }
            cakeItems.splice(i + 1, 1);
          } else {
            i++;
          }
        }

        return cakeItems.map(item => ({
          image: item.image,
          name: item.category.charAt(0).toUpperCase() + item.category.slice(1),
          category: item.category.charAt(0).toUpperCase() + item.category.slice(1),
          categorySlug: item.categorySlug
        }));
      },
      filterBySize() {
        if (this.selectedSize === 'All') {
          return this.cakes.slice(0, 20);
        } else {
          return [...this.cakes].filter(cake => cake.type === this.selectedSize).slice(0, 20);
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .filter-button-group {
    padding-left: 5%;
    padding-right: 5%;
  }
</style>
