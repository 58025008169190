<template>
  <div class="categories-navigation">
    <h5 class="category-item">
      <router-link :to="'/categories/' + category.slug"  :exact="true" :active-class="'active-category'">
        {{ category.name }}
      </router-link>
    </h5>

    <ul class="subcategory-list">
      <li v-for="(subcategory, index) in subcategories" :key="index">
        <router-link :to="'/categories/' + category.slug + '/subcategories/' + subcategory.slug" :exact="true" :active-class="'active-category'">
          <span class="subcategory-name">{{ subcategory.name }}</span>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
  export default {
    props: {
      category: {
        type: Object,
        default: {
          name: 'Birthdays',
          slug: 'birthdays',
        }
      },
      subcategories: {
        type: Array
      }
    }
  }
</script>

<style lang="scss" scoped>
  .categories-navigation {

    .category-item {
      font-size: 20px;
      a {
        color: black;
      }
    }

    ul.subcategory-list {
      list-style-type: none;
      margin-left: 1em;

      li {
        a {
          color: black;
          font-weight: 500;
        }
      }
    }

    .subcategory-name {
      position: relative;
    }

    .subcategory-name:after {
      content: '';
      position: absolute;
      bottom: 0.5;
      left: 0;
      width: 105%;
      height: 2px;
      background: #000;
      display: none;
    }

    .subcategory-name:hover:after {
      display: block;
    }
  }
</style>
