import Vue from 'vue';
import Vuex from 'vuex';
import bakery from './modules/bakery';
import layout from './modules/layout';
import categories from './modules/categories';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    bakery,
    layout,
    categories
  }
})
