<template>
  <div class="pagination-container">
    <div class="pagination-items">
      <span class="pagination-item" @click="clickedHandler('prev')" :class="{'prevent-page-click': currentPage === 1 }">
        <span class="text-decoration">PREV</span>
      </span>
      <span
        class="pagination-item desktop-only"
        v-for="page in totalPages" :key="page"
        @click="clickedHandler(page)"
      >
        <span :class="page === currentPage ? 'text-decoration-active': 'text-decoration'">{{ page }}</span>
      </span>

      <span @click="togglePopup" class="pagination-item mobile-only" style="text-decoration: underline">{{ currentPage }}</span>
      <span class="mobile-only" style="color: black">of</span>
      <span @click="togglePopup" class="pagination-item mobile-only">{{ totalPages }}</span>

      <span class="pagination-item" @click="clickedHandler('next')" :class="{'prevent-page-click': currentPage === totalPages }">
        <span class="text-decoration">NEXT</span>
      </span>

      <PopupContainer :current-page="currentPage" @click-page-item="clickedHandler" :totalPage="totalPages" :isDisplay="isDisplayPopup"></PopupContainer>
    </div>
  </div>
</template>

<script>
  import PopupContainer from './PopupContainer/PopupContainer.vue';

  export default {
    components: { PopupContainer },
    props: {
      totalPages: {
        type: Number
      },
      currentPage: {
        type: Number
      }
    },
    data() {
      return {
        isDisplayPopup: false
      }
    },
    methods: {
      clickedHandler: function(item) {
        this.isDisplayPopup = false;
        if (item === 'prev' && this.currentPage > 1) {
          this.$emit('clicked-page-item', this.currentPage - 1);
        } else if (item === 'next' && this.currentPage < this.totalPages) {
          this.$emit('clicked-page-item', this.currentPage + 1);
        } else if (item > 0 && item <= this.totalPages) {
          this.$emit('clicked-page-item', parseInt(item));
        }
      },
      togglePopup: function() {
        this.isDisplayPopup = !this.isDisplayPopup;
      }
    }
  }
</script>

<style lang="scss" scoped>
  .pagination-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: center;

    .pagination-items {
      position: relative;
      box-shadow: 0 3px 3px rgba(0,0,0,0.2);
      border-bottom: 1px solid #D4D4D4;
      display: flex;
      justify-content: space-around;
      border-radius: 4px;
      padding-top: 0.5em;
      padding-bottom: 0.5em;

      .desktop-only {
        @include small-screen {
          display: none;
        }
      }

      .mobile-only {
        @include large-screen {
          display: none;
        }
      }

      .pagination-item {
        width: 4em;
        font-weight: 700;
        text-align: center;
				color: black;

				@include large-screen {
					&:hover {
						cursor: pointer;
					}
				}
      }

      .text-decoration-active, .text-decoration {
        position: relative;
      }

      .text-decoration:after, .text-decoration-active:after {
        content: '';
        position: absolute;
        bottom: 0.5;
        left: 0;
        width: 105%;
        height: 2px;
        background: #000;
      }

      .text-decoration-active:after, .text-decoration:hover:after {
        display: block;
      }

      .text-decoration:after {
        display: none;
      }

      .prevent-page-click {
        cursor: not-allowed !important;
        text-decoration: none;
        opacity: 0.5;
      }
    }
  }
</style>
