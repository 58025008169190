<template>
  <Fragment>
    <h1 v-if="headerType === 1" :class="['title', 'is-1']">
      <slot></slot>
    </h1>
    <h1 v-if="headerType === 2" :class="['title', 'is-2']">
      <slot></slot>
    </h1>
    <h1 v-if="headerType === 3" :class="['title', 'is-3']">
      <slot></slot>
    </h1>
    <h1 v-if="headerType === 4" :class="['title', 'is-4']">
      <slot></slot>
    </h1>
    <h1 v-if="headerType === 5" :class="['title', 'is-5']">
      <slot></slot>
    </h1>
    <h1 v-if="headerType === 6" :class="['title', 'is-6']">
      <slot></slot>
    </h1>
  </Fragment>
</template>

<script>
  import { Fragment } from 'vue-fragment';

  export default {
    components: { Fragment },
    props: {
      headerType: {
        type: Number
      }
    }
  }
</script>

<style lang="scss" scoped>
  .title {
    padding-left: 5%;
    padding-right: 5%;
    font-weight: 700;
    margin-top: 1em !important;
    color: black !important;
  }

  .title.is-5 {
    font-size: 16px;
  }
</style>
