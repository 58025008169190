<template>
  <transition name="slide">
    <div v-if="isShow" class="modal-item">
      <div class="" @click="BackdropClickedHandler"></div>
      <slot></slot>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    isShow: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    BackdropClickedHandler: function() {
      this.$emit('backdrop-clicked');
    }
  }
}
</script>

<style lang="scss" scoped>
  .modal-item {
    position: fixed;
    overflow: visible;
    width: 100%;
    z-index: 150;
    bottom: 0;
    left: 0;
    right: 0;
    top: 5em;
    height: 500%;
    display: flex;

    @include large-screen {
      display: none;
    }
  }

  // transition
  .slide-enter-active {
    transition: transform .3s linear;
    -webkit-transition: transform .3s linear;
    -moz-transition: transform .3s linear;
    -o-transition: transform .3s linear;
  }
  .slide-leave-active {
    transition: transform 0.4s linear;
    -webkit-transition: transform 0.4s linear;
    -moz-transition: transform 0.4s linear;
    -o-transition: transform 0.4s linear;
  }
  .slide-enter, .slide-leave-to {
    transform: translateX(-100%);
  }
</style>
