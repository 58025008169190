<template>
  <Fragment>
    <SortAndFilterCakesMobile
        @close-sort-and-filter-modal="isFilterAndSortMobileDisplay = false"
        @on-submitted-modal-filter-mobile="onSubmittedMobileModal"
        :is-display="isFilterAndSortMobileDisplay"
        :selected-cake-type="selectedType"
        :selected-sort-type="currentSortType"
    >
    </SortAndFilterCakesMobile>
    <div class="category-header">
      <div class="category-header-left">
        <template v-if="breadcrumbs">
          <span class="category-title" v-if="breadcrumbs[1].text">{{
              isCategoryPage ? category.name : subcategory.name
            }}</span>
          <Breadcrumbs
              v-if="breadcrumbs[0].route && breadcrumbs[1].text"
              :items="breadcrumbs"
          ></Breadcrumbs>

          <SortContainerMobile
              v-if="!isCategoryPage"
              :totalCakes="cakesObject.totalProducts"
              @click-filter-toggle-mobile="isFilterAndSortMobileDisplay = true"
          >
          </SortContainerMobile>
        </template>
      </div>

      <div v-if="!isCategoryPage" class="category-header-right">
        <SortContainer @on-sort-option-click="sortOptionClickedHandler"></SortContainer>
      </div>
    </div>

    <div class="category-content">
      <div class="category-content-left">
        <StyleSelection
            v-if="!isCategoryPage"
            :selectedValue="selectedType"
            @select-cake-type="selectedCakeTypeHandler">
        </StyleSelection>

        <CategoriesNavigation
            :category="{ name: category.name, slug: category.slug }"
            :subcategories="subcategories"
        >
        </CategoriesNavigation>
      </div>
      <div class="category-content-right">
        <template v-if="cakesObject.totalProducts > 0 && !isLoading">
          <Products v-if="!isCategoryPage" :itemType="'product'" :products="cakesObject.cakesResult"></Products>
          <Products v-else-if="isCategoryPage" :itemType="'subcategory'" :products="cakesObject.cakesResult"></Products>
          <Pages @clicked-page-item="pageClickedHandler" :totalPages="cakesObject.totalPages"
                 :currentPage="currentPage"></Pages>
        </template>

        <template v-if="cakesObject.totalProducts === 0 && !isLoading">
          <h3>Oops!!! Cakes not found!!!</h3>
        </template>

        <template>
          <Spinner :isDisplay="isLoading"></Spinner>
        </template>
      </div>
    </div>
  </Fragment>
</template>

<script>
import {Fragment} from 'vue-fragment';
import {mapState} from 'vuex';
import {mapMutations} from 'vuex';
import {HIDE_SEARCH_BAR} from '@/store/mutation-types';
import Title from '../../components/UI/Title/Title';
import Category from '../../services/Category';
import Product from '../../services/Product';
import Breadcrumbs from '../../components/UI/Breadcrumbs/Breadcrumbs';
import StyleSelection from '../../components/StyleSelection/StyleSelection';
import CategoriesNavigation from '../../components/CategoriesNavigation/CategoriesNavigation';
import Products from '../../components/Products/Products';
import Pages from '../../components/UI/Pages/Pages';
import Spinner from '../../components/UI/Spinner/Spinner';
import SortContainer from '../../components/UI/SortContainer/SortContainer';
import SortContainerMobile from '../../components/UI/SortContainerMobile/SortContainerMobile';
import SortAndFilterCakesMobile from "../../components/UI/SortAndFilterCakesMobile/SortAndFilterCakesMobile";

const ITEMS_PER_PAGE_MOBILE = 15;

export default {
  components: {
    Fragment,
    Title,
    Breadcrumbs,
    StyleSelection,
    CategoriesNavigation,
    Products,
    Pages,
    Spinner,
    SortContainer,
    SortContainerMobile,
    SortAndFilterCakesMobile
  },
  name: 'Category',
  data() {
    return {
      isCategoryPage: true,
      subcategories: [],
      category: {
        slug: 'birthdays',
        name: 'Birthdays'
      },
      subcategory: {
        slug: '',
        name: ''
      },
      breadcrumbs: null,
      currentPage: 1,
      currentSortType: 'search-magnitude',
      selectedType: 'All',
      cakesObject: {},
      isSortOptionsDisplay: false,
      isLoading: false,
      isFilterAndSortMobileDisplay: false
    }
  },
  created: function () {
    this.HIDE_SEARCH_BAR();
    this.category.slug = this.$router?.history?.current?.params?.categorySlug;

    if (this.$router?.history?.current?.params?.subcategorySlug) {
      this.subcategory.slug = this.$router?.history?.current?.params?.subcategorySlug;
      this.isCategoryPage = false;
    } else {
      this.subcategory.slug = '';
      this.isCategoryPage = true;
    }

    this.loadData();
  },
  computed: {
    ...mapState({
      bakerySlug: state => state.bakery.bakery.slug,
      isMobileScreen: state => state.layout.isMobileScreen
    }),
    propertiesComputed() {
      return `${this.currentPage}|${this.currentSortType}|${this.selectedType}`;
    }
  },
  watch: {
    async $route(to) {
      this.category.slug = to.params.categorySlug;
      if (this.$router?.history?.current?.params?.subcategorySlug) {
        this.subcategory.slug = this.$router?.history?.current?.params?.subcategorySlug;
        this.isCategoryPage = false;
      } else {
        this.subcategory.slug = '';
        this.isCategoryPage = true;
      }
      await this.loadData();
    },
    async propertiesComputed() {
      this.isLoading = true;
      const productInstance = new Product();
      let result;
      if (!this.isMobileScreen) {
        result = await productInstance.productsFilter(this.category.slug, this.subcategory.slug, this.selectedType, this.currentSortType, this.currentPage);
      } else {
        result = await productInstance.productsFilter(this.category.slug, this.subcategory.slug, this.selectedType, this.currentSortType, this.currentPage, ITEMS_PER_PAGE_MOBILE);
      }

      this.cakesObject = {...result};
      this.isLoading = false;
    },
    isMobileScreen() {
      this.loadData();
    }
  },
  methods: {
    ...mapMutations('layout', [HIDE_SEARCH_BAR]),
    selectedCakeTypeHandler: function (type) {
      this.selectedType = type;
      this.currentPage = 1;
    },
    pageClickedHandler: function (page) {
      this.currentPage = page;
    },
    sortOptionClickedHandler: function (value) {
      this.currentSortType = value;
    },
    onSubmittedMobileModal: function (selectedSortType, selectedCakeType) {
      this.isFilterAndSortMobileDisplay = false;
      this.currentPage = 1;
      this.selectedType = selectedCakeType;
      this.currentSortType = selectedSortType;
    },
    loadData: async function () {
      const categoryInstance = new Category();
      const productInstance = new Product();
      this.isLoading = true;
      this.selectedType = 'All';
      const category = await categoryInstance.getCategoryBySlug(this.category.slug);
      // const subcategories = await categoryInstance.getSubcategoriesByCategorySlug(this.category.slug.toLowerCase());
      // this.subcategories = [...subcategories];
      this.category.name = category.name;
      const breadcrumbs = [];
      breadcrumbs.push(
          {
            text: 'Home',
            route: `/bakeries/${this.bakerySlug}`
          },
          {
            text: this.category.name,
            route: `/categories/${this.category.slug}`
          }
      );
      if (!this.isCategoryPage) {
        const subcategory = await categoryInstance.getCategoryBySlug(this.subcategory.slug);
        this.subcategory.name = subcategory.name;
        breadcrumbs.push({
          text: this.subcategory.name,
          route: `/categories/${this.category.slug}/subcategories/${this.subcategory.slug}`
        });
      }
      this.breadcrumbs = [...breadcrumbs];
      this.currentPage = 1;
      let result;
      const ITEMS_PER_PAGE = this.isMobileScreen ? 15 : 20;
      if (!this.isCategoryPage) {
        result = await productInstance.productsFilter(this.category.slug, this.subcategory.slug, this.selectedType, this.currentSortType, this.currentPage, ITEMS_PER_PAGE);
      } else {
        result = await productInstance.getFirstCakeOfEachSubcategory(this.category.slug, this.currentPage, ITEMS_PER_PAGE);
      }
      this.cakesObject = {...result};
      let subcategoriesAvaiArr = [];
      const cakeResults = this.cakesObject.cakesResult;
      subcategoriesAvaiArr = await productInstance.getProductsOfEachSubcategory(this.category.slug);

      this.subcategories = [...subcategoriesAvaiArr.subCategoriesHasCakes];
      this.isLoading = false;
    }
  }
}
</script>
<style lang="scss">
.category-header {
  background-color: #F4F4F4;
  @include small-screen {
    background-color: white;
  }
  padding-top: 0.5em;
  padding-bottom: 1em;
  line-height: 1;
  display: flex;
  justify-content: space-between;

  @include small-screen {
    display: block;
  }

  .category-header-left {
    margin-left: 5%;
    display: flex;
    flex-direction: column;

    @include small-screen {
      margin-right: 5%;
    }

    h3, .category-title {
      margin-bottom: 0;

      @include small-screen {
        text-align: center;
      }
    }

    .category-title {
      font-size: 26px;
    }
  }

  .category-header-right {
    margin-right: 7%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    @include small-screen {
      display: none;
    }
  }
}

.category-content {
  display: flex;
  padding-top: 1.5em;
  min-height: 45em;

  @include small-screen {
    padding-top: 0;
  }

  @include small-screen {
    justify-content: center;
  }

  .category-content-left {
    flex-basis: 20%;
    padding-left: 5%;

    @include small-screen {
      display: none;
    }
  }

  .category-content-right {
    flex-basis: 90%;
  }
}
</style>
