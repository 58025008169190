<template>
  <div class="sort-container-mobile mobile-only">
    <p>{{ totalCakes }} {{ totalCakes > 1 ? 'Results' : 'Result' }}</p>
    <p class="sort-modal-toggle" @click="onFilterToggleMobile">Filter/Sort</p>
  </div>
</template>

<script>
  export default {
    props: {
      totalCakes: {
        type: Number
      }
    },
    methods: {
      onFilterToggleMobile: function() {
        this.$emit('click-filter-toggle-mobile');
      }
    }
  }
</script>

<style lang="scss" scoped>
  .sort-container-mobile {
    display: flex;
    justify-content: space-between;
    margin-top: 1em;
    @include large-screen {
      display: none;
    }
    .sort-modal-toggle {
      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
</style>
