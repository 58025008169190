<template>
  <div class="category-item">
    <router-link :to="'/categories/' + categorySlug">
      <div class="category-item-image">
        <figure>
          <img :src="image" alt="Placeholder image">
        </figure>
      </div>
      <div class="category-item-title">
        <span class="title is-6 title-underline">{{ name | categoryNameTransform }}</span>
      </div>
    </router-link>
  </div>
</template>

<script>
  export default {
    props: {
      image: {
        type: String
      },
      name: {
        type: String
      },
      categorySlug: {
        type: String
      }
    }
  };
</script>

<style lang="scss" scoped>
  .category-item {
    display: flex;
    flex-direction: column;
    padding-left: 0.5em;
    padding-right: 0.5em;

    @include small-screen {
      padding-left: 0.2em;
      padding-right: 0.2em;
    }

    .category-item-image {
      padding-top: 10%;
      padding-bottom: 10%;
      border: 1px solid rgb(241, 241, 241);

      figure {
        @include small-screen {
          margin-right: 1em;
          margin-left: 1em;
        }
      }
    }

    .category-item-title {
      padding-top: 1em;
      padding-bottom: 1em;

      .title {
        color: black;
      }
    }

    &:focus {
      outline: none !important;
    }

    .title-underline {
      position: relative;
    }

    .title-underline:after {
      content: '';
      position: absolute;
      bottom: 3;
      left: 0;
      width: 100%;
      height: 2px;
      background: #000;
      display: none;
    }

    .title-underline:hover:after {
      display: block;
    }
  }
</style>
