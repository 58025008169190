<template>
  <Fragment>
    <div class="category-header">
      <div class="category-header-left">
        <template v-if="breadcrumbs">
          <h3 v-if="product.name">{{ product.name }}</h3>
          <Breadcrumbs
              v-if="breadcrumbs[0].route && breadcrumbs[1].text"
              :items="breadcrumbs"
          ></Breadcrumbs>
        </template>
      </div>
    </div>

    <div class="category-content">
      <div class="category-content-left">
        <CategoriesNavigation
            :category="{ name: category.name, slug: category.slug }"
            :subcategories="subcategories"
        >
        </CategoriesNavigation>
      </div>

      <div class="category-content-right">
        <div class="product-content">
          <div class="product-content-left">
            <div class="product-image" v-if="!product.salsifyChildrens">
              <img :src="product.image" :alt="product.name"/>
            </div>
            <div class="product-image carousel-slider" v-else>
              <VueSlickCarousel v-bind="settings" ref="carousel" @afterChange="productSlideChange">
                <div v-for="productChild in product.salsifyChildrens">
                  <img :src="productChild.image" :alt="productChild.name"/>
                </div>
              </VueSlickCarousel>
            </div>
          </div>

          <div class="product-content-right">
            <h3 class="product-title">{{ product.name }}</h3>
            <p class="product-type">{{ product.type === 'PhotoCakes' ? 'PhotoCake®' : product.type }}</p>
            <PinterestButton></PinterestButton>
            <p class="product-description">{{ product.description }}</p>
            <p class="product-id">Cake ID: {{ product.id }}</p>
            <template v-if="product.chokingHazardWarning">
              <ChokingHazardWarning></ChokingHazardWarning>
            </template>
            <div v-if="product.salsifyChildrens">
              <select @change="onChangeDropdown" ref="salsifyChildrensDropdown" v-model="currentProductSelected">
                <option v-for="(item, idx) in product.salsifyChildrens" :key="item.id" :value="idx">{{ item.name }}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Fragment>
</template>

<script>
import {Fragment} from 'vue-fragment';
import {mapMutations, mapState} from "vuex";

import {HIDE_SEARCH_BAR} from "@/store/mutation-types";
import Product from "@/services/Product";
import Category from "@/services/Category";
import CategoriesNavigation from '../../components/CategoriesNavigation/CategoriesNavigation';
import Breadcrumbs from "@/components/UI/Breadcrumbs/Breadcrumbs";
import PinterestButton from "@/components/UI/PinterestButton/PinterestButton";
import ChokingHazardWarning from "@/components/ChokingHazardWarning/ChokingHazardWarning";

import VueSlickCarousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';

export default {
  components: {
    Fragment,
    Breadcrumbs,
    CategoriesNavigation,
    PinterestButton,
    ChokingHazardWarning,
    VueSlickCarousel
  },
  data() {
    return {
      product: {},
      subcategories: [],
      category: {},
      subcategory: {},
      breadcrumbs: null,
      isLoading: false,
      settings: {
        draggable: true,
        slidesToShow: 1,
        arrows: false,
        dots: true,
        swipeToSlide: true,
        infinite: false
      },
      currentProductSelected: 0
    }
  },
  async created() {
    this.HIDE_SEARCH_BAR();
    const cakeSlug = this.$router?.history?.current?.params?.cakeSlug;
    const productInstance = new Product();
    const categoryInstance = new Category()
    this.product = await productInstance.getProductBySlug(cakeSlug);
    this.category = await categoryInstance.getCategoryBySlug(this.product.categorySlug);
    this.subcategory = await categoryInstance.getCategoryBySlug(this.product.subCategorySlug);

    const subcategoriesAvaiArr = await productInstance.getProductsOfEachSubcategory(this.category.slug)
    this.subcategories = [...subcategoriesAvaiArr.subCategoriesHasCakes];
    const breadcrumbs = [];
    breadcrumbs.push(
        {
          text: 'Home',
          route: `/bakeries/${this.bakerySlug}`
        },
        {
          text: this.category.name,
          route: `/categories/${this.category.slug}`
        },
        {
          text: this.subcategory.name,
          route: `/categories/${this.category.slug}/subcategories/${this.subcategory.slug}`
        },
        {
          text: this.product.name,
          route: `/cakes/${this.product.slug}`
        }
    );
    this.breadcrumbs = [...breadcrumbs];
  },
  methods: {
    ...mapMutations('layout', [HIDE_SEARCH_BAR]),
    onChangeDropdown(e) {
      this.$refs.carousel.goTo(e.target.options.selectedIndex)
    },
    productSlideChange(slideIndex) {
      this.currentProductSelected = slideIndex;

    }
  },
  computed: {
    ...mapState({
      bakerySlug: state => state.bakery.bakery.slug,
    })
  }
}
</script>

<style lang="scss" scoped>
select {
  font-family: 'Open Sans', sans-serif !important;
  color: black;
  margin: 10px 0;
}

.product-image {
  max-width: 25em;

  @include small-screen {
    max-width: 20em;
  }
}

.content ul {
  margin-left: 0 !important;
}

.product-content {
  display: flex;
  justify-content: space-around;

  @include small-screen {
    flex-direction: column;
  }

  .product-content-left {
    flex-basis: 35%;
    display: flex;
    justify-content: center;
    align-items: center;

    @include small-screen {
      margin-bottom: 2em;
    }

    .product-image {
      box-shadow: 0 0 0 0.2rem #e1e1e1;
      border-radius: 0.2em;
      padding: 1em;
    }
  }

  .product-content-right {
    flex-basis: 55%;

    .product-title {
      font-weight: 400;
      margin-bottom: 0.2em;
    }

    .product-description {
      width: 70%;
      margin-top: 1em;
      margin-bottom: 1em;

      @include small-screen {
        width: 90%;
      }

    }

    .product-id {
      margin-bottom: 0;
      font-weight: 600;
      font-size: 16px;
    }
  }
}
</style>
