<template>
  <ul class="navigation-items">
    <NavigationItem v-for="item in items" :key="item.id" :item="item"></NavigationItem>
  </ul>
</template>

<script>
  import NavigationItem from './NavigationItem/NavigationItem';

  export default {
    props: {
      items: Array,
      default: []
    },
    components: { NavigationItem }
  }
</script>

<style lang="scss" scoped>
  .navigation-items {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
</style>
