<template>
  <div class="products-container">
    <Product
      v-for="(product, index) in reorder(products)"
      :key="index"
      :name="product.name"
      :slug="product.slug"
      :image="product.image"
      :category="product.category"
      :categorySlug="product.categorySlug"
      :subcategory="product.subCategory"
      :subcategorySlug="product.subCategorySlug"
      :itemType="itemType"
      :cakeType="product.type"
			:addablePhotoCake="product.addablePhotoCake"
			:isCakeSizeFormatAvailable="product.isCakeSizeFormatAvailable"
			:isCupcakeSizeFormatAvailable="product.isCupcakeSizeFormatAvailable"
      :sold="product.sold"
      :id="product.id"
      :searchMagnitude="product.searchMagnitude"
    >
    </Product>
  </div>
</template>

<script>
  import Product from './Product/Product';

  export default {
    components: { Product },
    props: {
      products: {
        type: Array
      },
      itemType: {
        type: String,
        default: 'subcategory'
      }
    },
    created() {
    },
    methods: {
      reorder: (prods) => {
        return prods.slice().sort((a, b) => {
          return a.searchMagnitude - b.searchMagnitude;
        });
      }
    }
  }
</script>

<style lang="scss" scoped>
  .products-container {
    display: flex;
    flex-wrap: wrap;

    @include small-screen {
      justify-content: center;
    }
  }
</style>
