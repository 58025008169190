<template>
  <transition name="slide-fade-up">
    <div v-if="isDisplay" class="pagination-popup mobile-only">
      <PageItem :active="page === currentPage" @click-item="clickedPageHandler" v-for="page in totalPage" :key="page" :pageNumber="page"></PageItem>
    </div>
  </transition>
</template>

<script>
  import PageItem from './PageItem/PageItem';

  export default {
    components: { PageItem },
    props: {
      isDisplay: {
        type: Boolean,
        default: false
      },
      totalPage: {
        type: Number
      },
			currentPage: {
      	type: Number
			}
    },
    methods: {
      clickedPageHandler: function(pageNumber) {
        this.$emit('click-page-item', pageNumber);
      }
    }
  }
</script>

<style lang="scss" scoped>
  .mobile-only {
    @include large-screen {
      display: none;
    }
  }

  .pagination-popup {
    position: absolute;
    width: 100%;
    bottom: 105%;
    box-shadow: 0 3px 3px rgba(0,0,0,0.2);
    border-bottom: 1px solid #D4D4D4;
    background-color: white;
  }

  // transition
  .slide-fade-up-enter-active {
    transition: transform .3s linear, opacity .3s linear;
    -webkit-transition: transform .3s linear, opacity .3s linear;
    -moz-transition: transform .3s linear, opacity .3s linear;
    -o-transition: transform .3s linear, opacity .3s linear;
  }

  .slide-fade-up-leave-active {
    transition: transform .3s linear, opacity .3s linear;
    -webkit-transition: transform .3s linear, opacity .3s linear;
    -moz-transition: transform .3s linear, opacity .3s linear;
    -o-transition: transform .3s linear, opacity .3s linear;
  }

  .slide-fade-up-enter, .slide-fade-up-leave-to {
    transform: translateY(20px);
    opacity: 0;
  }
</style>
