<template>
	<div class="cake-choking-hazard">
		<img id="warning-icon" class="icon icon--warning" style="-webkit-mask-image: url('https://newarc.imgix.net/ui/warning.svg?auto=compress'); mask-image: url('https://newarc.imgix.net/ui/warning.svg?auto=compress')" />
		<p>Warning: choking hazard small parts - not for children under 3.</p>
	</div>
</template>

<script>
export default {
	name: "ChokingHazardWarning"
}
</script>

<style lang="scss" scoped>
	.cake-choking-hazard {
		padding: 1rem 0;
		vertical-align: center;

		.icon.icon--warning {
			width: 1.4rem;
			height: 1.2rem;
			background-color: #f9423a;
			margin: 0;
		}

		.icon {
			display: inline-block;
			vertical-align: middle;
			-webkit-mask-size: 100% 100%;
		}

		p {
			display: inline;
			font-weight: 700;
		}
	}
</style>
