import Vue from 'vue';
import VueRouter from 'vue-router';

import LandingPage from '@/screens/LandingPage/LandingPage.vue';
import Category from '@/screens/Category/Category.vue';
import ProductDetail from "@/screens/ProductDetail/ProductDetail";
import DataSeeding from "@/screens/DataSeeding/DataSeeding";
import SalsifyDataSeeding from "@/screens/DataSeeding/SalsifyDataSeeding";
import CakeResultPage from "@/screens/CakeResultPage/CakeResultPage";

Vue.use(VueRouter);

const routes = [
  {
    name: "landing",
    path: '/bakeries/:slug',
    component: LandingPage
  },
  {
    name: "category",
    path: '/categories/:categorySlug/subcategories/:subcategorySlug',
    component: Category
  },
  {
    name: "subcategory",
    path: '/categories/:categorySlug',
    component: Category,
  },
  {
    name: "cake",
    path: "/cakes/:cakeSlug",
    component: ProductDetail
  },
  {
    name: "cakeResult",
    path: "/cakes",
    component: CakeResultPage
  },
  {
    name: "seeding",
    path: "/data/seeding",
    component: DataSeeding
  },
  {
    name: "salsify",
    path: "/data/salsify",
    component: SalsifyDataSeeding
  },
  {
    path: '*',
    redirect: '/bakeries/sprinkles-and-bling'
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
