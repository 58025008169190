<template>
  <div class="sort-icon">
    <p @click="isSortOptionsDisplay = !isSortOptionsDisplay">Sort</p>
    <transition name="fade">
      <div v-if="isSortOptionsDisplay" class="sort-options">
        <p
          class="sort-option"
          v-for="option in options"
          :key="option.value"
          v-on:click="selected = option.value"
          @click="optionSelectedHandler(option.value)"
        ><span :class="selected === option.value ? 'active-option' : 'option-text'">{{ option.text }}</span></p>
      </div>
    </transition>
  </div>
</template>

<script>
  import SortIcon from '@/assets/images/SortIcon.svg';
  export default {
    components: { SortIcon },
    props: {
      options: {
        type: Array,
        default: function() {
          return [
            {
              text: 'Search Magnitude',
              value: 'search-magnitude'
            },
            {
              text: 'Best Sellers',
              value: 'best-sellers'
            },
            {
              text: 'A - Z',
              value: 'az'
            },
            {
              text: 'Z - A',
              value: 'za'
            },

          ]
        }
      }
    },
    data() {
      return {
        isSortOptionsDisplay: false,
        isActive: false,
        selected: 'search-magnitude',
      }
    },
    methods: {
      optionSelectedHandler(value) {
        this.isSortOptionsDisplay = false;
        this.$emit('on-sort-option-click', value);
      }
    }
  }
</script>

<style lang="scss" scoped>
  .sort-icon {
    position: relative;
    cursor: pointer;
    > p {
      position: absolute;
      top: -0.6em;
    }

    > p:after {
      content: '';
      position: absolute;
      bottom: -5px;
      left: 0;
      width: 100%;
      height: 2px;
      background: #000;
      display: none;
    }

    > p:hover:after, .option-text:hover:after {
      display: block;
    }

    > svg {
      position: absolute;
      top: -0.5em;
      left: -1.2em;
    }
    .sort-options {
      width: 11em;
      margin-top: 0.5em;
      position: absolute;
      right: -2em;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
      background-color: white;
      top: 0.5em;
      line-height: 0.5;
      .sort-option {
        line-height: 1;
        padding: 0.5em;
        margin-bottom: 0;
        &:hover {
          background-color: #e1e1e1;
        }
      }
    }
  }
  // transition
  .fade-enter-active {
    transition: transform .3s linear, opacity .5s linear;
    -webkit-transition: transform .3s linear, opacity .5s linear;
    -moz-transition: transform .3s linear, opacity .5s linear;
    -o-transition: transform .3s linear, opacity .5s linear;
  }
  .fade-leave-active {
    transition: transform .3s linear, opacity .5s linear;
    -webkit-transition: transform .3s linear, opacity .5s linear;
    -moz-transition: transform .3s linear, opacity .5s linear;
    -o-transition: transform .3s linear, opacity .5s linear;
  }
  .fade-enter, .fade-leave-to {
    transform: translateY(-20px);
    opacity: 0;
  }

  .option-text, .active-option {
    position: relative;
  }
  .option-text:after, .active-option:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: #000;
  }

  .option-text:after {
    display: none;
  }

  .option-text:hover:after, .active-option:after {
    display: block;
  }
</style>
