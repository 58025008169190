/* bakery module */
export const GET_CURRENT_BAKERY = 'GET_CURRENT_BAKERY';

/* Layout mobule */
export const TOGGLE_DESKTOP_DROPDOWN = 'TOGGLE_DESKTOP_DROPDOWN';
export const SHOW_DESKTOP_DROPDOWN = 'SHOW_DESKTOP_DROPDOWN';
export const CLOSE_DESKTOP_DROPDOWN = 'CLOSE_DESKTOP_DROPDOWN';
export const TOGGLE_MOBILE_DROPDOWN = 'TOGGLE_MOBILE_DROPDOWN';
export const TOGGLE_SIDEDRAW_MOBILE = 'TOGGLE_SIDEDRAW_MOBILE';
export const HIDE_SIDEDRAW_MOBILE = 'HIDE_SIDEDRAW_MOBILE';

export const SHOW_SEARCH_BAR = 'SHOW_SEARCH_BAR';
export const HIDE_SEARCH_BAR = 'HIDE_SEARCH_BAR';
export const TOGGLE_SEARCH_BAR = 'TOGGLE_SEARCH_BAR';

export const SHOW_BAKERY_INFO_ICONS = 'SHOW_BAKERY_INFO_ICONS';
export const HIDE_BAKERY_INFO_ICONS = 'HIDE_BAKERY_INFO_ICONS';
export const TOGGLE_BAKERY_INFO_ICONS = 'TOGGLE_BAKERY_INFO_ICONS';

export const SCROLLED_DOWN = 'SCROLLED_DOWN';
export const SCROLLED_TOP = 'SCROLLED_TOP';

export const SHOW_MODAL_MOBILE = 'SHOW_MODAL_MOBILE';
export const HIDE_MODAL_MOBILE = 'HIDE_MODAL_MOBILE';
export const TOGGLE_MODAL_MOBILE = 'TOGGLE_MODAL_MOBILE';

export const TOGGLE_MOBILE_SEARCH_INPUT = 'TOGGLE_MOBILE_SEARCH_INPUT';
export const SHOW_MOBILE_SEARCH_INPUT = 'SHOW_MOBILE_SEARCH_INPUT';
export const HIDE_MOBILE_SEARCH_INPUT = 'HIDE_MOBILE_SEARCH_INPUT';

/* categories module */
export const GET_ALL_CATEGORIES = 'GET_ALL_CATEGORIES';

/* set current screen size status */
export const SET_IS_SMALL_SCREEN_SIZE = 'SET_IS_SMALL_SCREEN_SIZE';
