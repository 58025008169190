<template>
	<div class="cake-indicators-container">
		<div class="cake-indicators">
			<div v-if="isCakeSizeFormatAvailable" class="cake-indicator">
				<div class="cake-indicator-icon" style="padding-top: 3px">
					<CakeIcon></CakeIcon>
				</div>
				<div class="cake-indicator-text">
					<p>{{ 'cake' | capitalizeTextTransform }}</p>
				</div>
			</div>

			<div v-if="isCupcakeSizeFormatAvailable" class="cake-indicator">
				<div class="cake-indicator-icon">
					<CupcakeIcon></CupcakeIcon>
				</div>
				<div class="cake-indicator-text">
					<p>{{ 'cupcake' | capitalizeTextTransform }}</p>
				</div>
			</div>

			<div v-if="addablePhotoCake" class="cake-indicator">
				<div class="cake-indicator-icon">
					<PhotoCameraIcon></PhotoCameraIcon>
				</div>
				<div class="cake-indicator-text">
					<p>{{ 'photocake®' | capitalizeTextTransform }}</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import CakeIcon from '@/assets/images/CakeIcon.svg';
import CupcakeIcon from '@/assets/images/CupcakeIcon.svg';
import PhotoCameraIcon from '@/assets/images/PhotoCameraIcon.svg';

export default {
	components: { CakeIcon, CupcakeIcon, PhotoCameraIcon },
	name: "CakeIndicators",
	props: {
		addablePhotoCake: {
			type: Boolean,
			default: true
		},
		isCakeSizeFormatAvailable: {
			type: Boolean,
			default: true
		},
		isCupcakeSizeFormatAvailable: {
			type: Boolean,
			default: true
		}
	}
}
</script>

<style lang="scss" scoped>
	.cake-indicators-container {
		width: 90%;
		margin: auto;
		display: flex;
		justify-content: center;
		align-content: center;

		.cake-indicators {
			width: 100%;
			margin: auto;
			display: flex;
			flex-direction: row;
			justify-content: center;

			.cake-indicator {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: space-evenly;
				margin-left: 0.5em;
				margin-right: 0.5em;

				@include larger_desktop {
					width: 3em
				}

				@include large_desktop {
					width: 3em;
				}

				@include desktop {
					width: 1.5em;
				}

				@include tablet {
					width: 3em;
				}

				@include mobile {
					width: 4em;
				}

				@include small-mobile {
					width: 4.5em;
				}

				.cake-indicator-icon {
					height: 2em;
				}

				@include large-screen {
					.cake-indicator-icon:hover + .cake-indicator-text > p {
						display: flex;
						font-size: x-small;
					}
				}

				.cake-indicator-text {
					height: 1em;

					p {
						font-size: 13px;
						font-weight: 700;

						@include large-screen {
							display: none;
						}

						@include tablet {
							font-size: x-small;
						}

						@include mobile {
							font-size: x-small;
						}

						@include small-mobile {
							font-size: x-small;
						}
					}
				}
			}
		}
	}
</style>
