<template>
  <div @scroll="scrollHandler($event)">
    <Layout :width="windowWidth">
      <router-view></router-view>
    </Layout>
  </div>
</template>

<script>
  import { mapMutations, mapState, mapActions } from 'vuex';
  import { HIDE_SEARCH_BAR, SHOW_SEARCH_BAR, SHOW_BAKERY_INFO_ICONS, HIDE_BAKERY_INFO_ICONS, SCROLLED_DOWN, SCROLLED_TOP, SHOW_MOBILE_SEARCH_INPUT, HIDE_MOBILE_SEARCH_INPUT, HIDE_SIDEDRAW_MOBILE } from './store/mutation-types';
  import Layout from './components/Layout/Layout';

  export default {
    name: 'App',
    data() {
      return {
        scrollPosition: window.scrollY,
        windowWidth: window.innerWidth,
        routeName: this.$router.history.current
      }
    },
    created: async function() {
      window.addEventListener('scroll', this.scrollHandler, { passive: true });
      await this.getAllCategories();
      if (localStorage.getItem('slug')) {
        await this.getBakery(localStorage.getItem('slug'));
      }
    },
    components: { Layout },
    mounted() {
      window.addEventListener('resize', () => {
        this.windowWidth = window.innerWidth
      }, { passive: true });
      window.addEventListener('scroll', () => {
				this.scrollPosition = window.scrollY;
			});
    },
    destroyed() {
      window.removeEventListener('scroll', this.scrollHandler);
    },
    computed: {
      ...mapState({
        categories: state => state.categories.categories,
				isMobileScreen: state => state.layout.isMobileScreen
      })
    },
    watch: {
      $route(to) {
        this.routeName = to.name;
        this.HIDE_SIDEDRAW_MOBILE();
        if (this.routeName !== 'category' && this.routeName !== 'subcategory' && this.routeName !== 'cake') {
          this.SHOW_MOBILE_SEARCH_INPUT();
          this.SHOW_SEARCH_BAR();
        } else {
          this.HIDE_MOBILE_SEARCH_INPUT();
          this.HIDE_SEARCH_BAR();
        }
      },
			scrollPosition(to, from) {
      	if (this.isMobileScreen && to > from && to > 0) {
      		this.HIDE_MOBILE_SEARCH_INPUT();
				}
			}
    },
    methods: {
      ...mapMutations('layout', [HIDE_SEARCH_BAR, SHOW_SEARCH_BAR, SHOW_BAKERY_INFO_ICONS, HIDE_BAKERY_INFO_ICONS, SCROLLED_DOWN, SCROLLED_TOP, SHOW_MOBILE_SEARCH_INPUT, HIDE_MOBILE_SEARCH_INPUT, HIDE_SIDEDRAW_MOBILE]),
      ...mapActions({
        getAllCategories: 'categories/getAllCategoriesAsync',
        getBakery: 'bakery/getBakeryAsync'
      }),
      scrollHandler: function() {
        if (window.scrollY <= 0) {
          this.SCROLLED_TOP();
          this.HIDE_BAKERY_INFO_ICONS();
          if (this.routeName !== 'category' && this.routeName !== 'subcategory' && this.routeName !== 'cake') {
            this.SHOW_SEARCH_BAR();
            this.SHOW_MOBILE_SEARCH_INPUT();
          }
        } else {
          this.SCROLLED_DOWN();
          this.HIDE_SEARCH_BAR();
          this.SHOW_BAKERY_INFO_ICONS();
        }
      }
    }
  }
</script>

<style lang="scss">
  body {
    font-family: 'Open Sans', sans-serif !important;
    color: black;
  }
</style>
