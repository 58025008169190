<template>
  <transition name="fade">
    <div class="search-bar-dropdown" v-if="isDisplay">
      <SearchInput></SearchInput>
      <BottomLine></BottomLine>
    </div>
  </transition>
</template>

<script>
  import SearchInput from '../../../UI/Input/SearchInput';
  import BottomLine from './BottomLine/BottomLine';

  export default {
    components: { SearchInput, BottomLine },
    props: {
      isDisplay: {
        type: Boolean,
        default: false
      }
    }
  }
</script>

<style lang="scss" scoped>
  .search-bar-dropdown {
    position: fixed;
    left: 0;
    height: 5em;
    width: 100%;
    background-color: #f4f0ed;

    @include small-screen {
      display: none;
    }
  }

  // transition
  .fade-enter-active {
    transition: opacity 0.5s ease;
    -webkit-transition: opacity 0.5s ease;
    -moz-transition: opacity 0.5s ease;
    -o-transition: opacity 0.5s ease;
  }
  .fade-leave-active {
    transition: opacity 0.5s ease;
    -webkit-transition: opacity 0.5s ease;
    -moz-transition: opacity 0.5s ease;
    -o-transition: opacity 0.5s ease;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
</style>
