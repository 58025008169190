<template>
  <div class="pills">
    <Pill v-for="(item, index) in items" :key="index" :item="item" :index="index" :isActive="index === activeIndex" @click-pill-item="pillClickHandler"></Pill>
  </div>
</template>

<script>
  import Pill from './Pill/Pill';

  export default {
    components: { Pill },
    data() {
      return {
        activeIndex: 0
      }
    },
    props: {
      items: Array
    },
    methods: {
      pillClickHandler(item, index) {
        this.activeIndex = index;
        this.$emit('pill-clicked-handler', item);
      }
    }
  }
</script>

<style lang="scss" scoped>
  .pills {
    display: flex;

    @include small-mobile {
      overflow: scroll;
    }

    @include mobile {
      overflow: scroll;
    }
  }
</style>
