import Vue from 'vue';

Vue.filter('capitalizeTextTransform', text => {
  return text.toUpperCase();
});

Vue.filter('categoryNameTransform', text => {
  let result = text.replace(/-/g, ' ');
  let separateWord = result.toLowerCase().split(' ');
  for (let i = 0; i < separateWord.length; i++) {
    separateWord[i] = separateWord[i].charAt(0).toUpperCase() + separateWord[i].substring(1);
  }
  return separateWord.join(' ');
});
