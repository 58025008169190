<template>
  <div class='bakery-information-mobile'>
    <div class="bakery-info">
      <span class="icon-text">
        <span class="icon">
          <i class="fas fa-info-circle"></i>
        </span>
        <span>Info</span>
      </span>
    </div>
    <div class="bakery-address">
      <span class="icon-text">
        <span class="icon">
          <i class="fas fa-map-marker-alt"></i>
        </span>
        <span>{{ bakery.shortAddress }}</span>
      </span>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState({
      bakery: state => state.bakery.bakery
    })
  }
}
</script>

<style lang="scss" scoped>
.bakery-information-mobile {
  @include large-screen {
    display: none;
  }

  @include small-screen {
    display: flex;
    justify-content: space-between;
  }
}

</style>
