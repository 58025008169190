<template>
  <footer class="footer">
    <div class="content has-text-centered">
      <p class="footer-address">{{bakery.street}},</p>
      <p class="footer-address">{{bakery.city}}</p>
      <p class="footer-address">{{bakery.phone}}</p>
      <p class="footer-terms">Terms • Privacy</p>
    </div>
  </footer>
</template>

<script>
  import { mapState } from 'vuex';

  export default {
    name: 'Footer',
    computed: {
      ...mapState({
        bakery: state => state.bakery.bakery
      })
    }
  }
</script>

<style lang="scss" scoped>
  .footer {
    background-color: #8e938c98;
    padding: 0.5em;
    color: black;

		.footer-address {
			margin-bottom: 0.3em;
		}

		.footer-terms {
			margin-top: 1em;
		}
  }
</style>
