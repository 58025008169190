<template>
  <Fragment>
    <header class="desktop-header">
      <nav :style="{ height: isScrolled ? '3em' : '5em' }">
        <div class="nav-left">
          <Logo :type="'desktop'" :image="image" :slug="slug"></Logo>
          <NavigationItems :items="categories"></NavigationItems>
        </div>
        <Icons></Icons>
      </nav>
      <SearchBar :isDisplay="isSearchBarDesktopDisplay"></SearchBar>
    </header>
    <header class="mobile-header">
      <nav :class="{ 'border-bottom-mobile': !isSearchInputMobileDisplay }" class="z-index1000">
        <Logo :type="'mobile'" :image="image" :slug="slug"></Logo>
        <IconsMobile></IconsMobile>
      </nav>
      <transition name="slide-fade">
        <SearchInput v-if="isSearchInputMobileDisplay" :isFloat="false"></SearchInput>
      </transition>
      <BakeryInformationBar
        :isDisplay="!isScrolled && isSearchInputMobileDisplay"
        :shortAddress="shortAddress">
      </BakeryInformationBar>
    </header>
  </Fragment>
</template>

<script>
  import { mapState } from 'vuex';
  import { Fragment } from 'vue-fragment';
  import Logo from './Logo/Logo';
  import NavigationItems from './NavigationItems/NavigationItems';
  import Icons from './Icons/Icons';
  import IconsMobile from './Icons/IconsMobile';
  import SearchBar from './SearchBar/SearchBar';
  import Banner from './Banner/Banner';
  import SearchInput from '../../UI/Input/SearchInput';
  import BakeryInformationBar from './BakeryInformationBar/BakeryInformationBar';
  import ModalMobile from './ModalMobile/ModalMobile'

  export default {
    components: { Fragment, Logo, NavigationItems, Icons, IconsMobile, SearchBar, Banner, SearchInput, BakeryInformationBar, ModalMobile },
    computed: {
      ...mapState({
        image: state => state.bakery.bakery.image,
        slug: state => state.bakery.bakery.slug,
        shortAddress: state => state.bakery.bakery.shortAddress,
        categories: state => state.categories.categories,
        isSearchBarDesktopDisplay: state => state.layout.searchBarDesktop.isDisplay,
        isSearchInputMobileDisplay: state => state.layout.mobileSearchInput.isDisplay,
        isScrolled: state => state.layout.isScrolledDown
      })
    }
  }
</script>

<style lang="scss" scoped>
  .desktop-header {
    display: block;
    width: 100%;
    max-width: inherit;
    position: fixed;
    top: 0;
    padding-top: 0.5em;
    z-index: 110;
    background-color: white;

    @include small-screen {
      display: none;
    }

    nav {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      background-color: white;
      padding-left: 5%;
      border-bottom: 2px solid #f4f0ed;
      transition: height 0.3s linear;
      -webkit-transition: height 0.3s linear;
      -moz-transition: height 0.3s linear;
      -o-transition: height 0.3s linear;

      .nav-left {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
      }
    }
  }

  .mobile-header {
    display: block;
    width: 100%;
    position: fixed;
    top: 0;
    padding-top: 0.5em;
    z-index: 110;
    background-color: white;

    @include large-screen {
      display: none;
    }

    nav {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      background-color: white;
      height: 5em;
    }

    .border-bottom-mobile {
      border-bottom: 2px solid #f4f0ed;
      z-index: 100;
    }
  }

  // transition
  .slide-fade-enter-active {
    transition: transform .5s linear, opacity .5s linear;
    -webkit-transition: .5s linear, opacity .5s linear;
    -moz-transition: .5s linear, opacity .5s linear;
    -o-transition: .5s linear, opacity .5s linear;
  }
  .slide-fade-leave-active {
    transition: transform .5s linear, opacity .5s linear;
    -webkit-transition: transform .5s linear, opacity .5s linear;
    -moz-transition: transform .5s linear, opacity .5s linear;
    -o-transition: transform .5s linear, opacity .5s linear;
  }
  .slide-fade-enter, .slide-fade-leave-to {
    transform: translateY(-50px);
    opacity: 0;
  }

  .z-index1000 {
    z-index: 1000;
  }
</style>
