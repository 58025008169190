<template>
  <div class="categories">
    <div
      v-for="(cake, index) in cakes"
      :key="index"
      class="category"
    >
      <router-link :to="'/categories/' + cake.categorySlug">
        <span class="category-name" v-if="checkLengthString(cake.category)">
          <span class="category-link">{{ cake.category | categoryNameTransform | capitalizeTextTransform }}</span>
        </span>
        <span class="category-name" v-else>
          <span class="category-link">
            {{ cake.category.split(" ")[0] | categoryNameTransform | capitalizeTextTransform }}<br>
            {{ cake.category.split(" ").slice(1).join(" ") | categoryNameTransform | capitalizeTextTransform }}
          </span>
        </span>
        <div class="cake-image">
          <figure><img :src="cake.image" alt="image" /></figure>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CategoryItems',
  props: {
    cakes: {
      type: Array
    }
  },
  methods: {
    checkLengthString(string) {
      return (string.split(" ").length <= 1)
    }
  }
}
</script>

<style lang="scss" scoped>
  .categories {
    display: flex;

    @include large-screen {
      flex-direction: row;
      justify-content: flex-start;
    }

    @include small-screen {
      flex-direction: column;
    }

    .category > a {
      display: flex;

      @include large-screen {
        flex-direction: column;
        margin: auto 1rem auto 1rem;
        flex-basis: 20%;
      }

      @include small-screen {
        flex-direction: row;
        margin: 1rem;
        flex-basis: 100%;
        justify-content: space-between;
        align-items: center;

        img {
          max-width: 50%;
          height: auto;
        }
      }

      .category-name {
        color: black;
        min-height: 70px;

        @include small-mobile {
          font-size: x-large;
        }

        @include mobile {
          font-size: xx-large;
        }

        @include tablet {
          font-size: xx-large;
        }

        @include desktop {
          font-size: large;
          height: 2em;
        }

        @include large_desktop {
          font-size: x-large;
          height: 70px;
        }

        @include larger_desktop {
          font-size: x-large;
        }
      }

      .category-link {
        position: relative;
        font-weight: 600;
      }

      .category-link:hover {
        border-bottom: 2px solid #000;
      }
    }

		.category:not(:last-child) > a {
			@include small-screen {
				border-bottom: 1px solid grey;
			}
		}
  }
</style>
