<template>
  <div class="location">
    <div class="location-item">
      <div><MapPin></MapPin></div>
      <span class="location-text">Store</span>
    </div>
    <div class="store-info">
      <p class="store-name">{{ storeName }}</p>
      <p>{{ street }},</p>
      <p>{{ city }}</p>
      <p>{{ storePhone }}</p>
    </div>
    <a v-bind:href= "urlAddress" target="_blank">
      <p class="get-direction">Get Directions</p>
    </a>
  </div>
</template>

<script>
  import { mapState, mapMutations } from 'vuex';
  import MapPin from '@/assets/images/MapPin.svg';

  export default {
    components: { MapPin },
    computed: {
      ...mapState({
        storePhone: state => state.bakery.bakery.phone,
        fullAddress: state => state.bakery.bakery.fullAddress,
        street: state => state.bakery.bakery.street,
        city: state => state.bakery.bakery.city,
        storeName: state => state.bakery.bakery.name,
        urlAddress: state => "https://maps.google.com/maps?saddr=&daddr=" + encodeURI(state.bakery.bakery.fullAddress)
      })
    }
  }
</script>

<style lang="scss" scoped>
  .location {
    display: flex;
    flex-direction: column;
    padding-bottom: 30px;
    margin-bottom: 30px;
    color: #000000;
  }

  .location-item {
    display: flex;
    padding: 30px 0 0 26px;
    color: #000000;
  }

  .store-info {
    font-size: 16px;
    padding: 5px 5px 0 4em;
    color: #000000;
  }

  .store-name {
    font-weight: 600;
    color: #000000;
  }

  .location-text {
    font-weight: bold;
    padding-left: 20px;
    width: 213px;
    height: 27px;
    font-size: 16px;
    color: #000000;
    text-decoration: none solid rgb(0, 0, 0);
    line-height: 27px;
    text-transform: uppercase;
  }

  .get-direction {
    padding-top: 8px;
    color: #000000;
    padding-left: 4em;
    text-decoration: underline;
    font-size: 16px;

    &:hover {
      text-decoration: none;
    }
  }
</style>
