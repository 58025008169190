<template>
  <div class="banner" v-if="isDisplay">
    <img :src="require('@/assets/images/banner-landing-image.png')" alt="banner" />
    <SearchInput v-if="hasInput"></SearchInput>
  </div>
</template>

<script>
  import SearchInput from '../../../UI/Input/SearchInput';

  export default {
    components: { SearchInput },
    props: {
      isDisplay: {
        type: Boolean,
        default: false
      },
      hasInput: {
        type: Boolean,
        default: true
      }
    }
  }
</script>

<style lang="scss" scoped>
  .banner {
    height: 5em;
    width: 100%;

    @include large-screen {
      position: fixed;
      left: 0;
    }

    img {
      width: 100%;
      object-fit: cover;
      height: 100%;
    }
  }
</style>
