import axios from 'axios';
import {ENTRY_POINT} from "@/constants/URLs";

const ITEMS_PER_PAGE = 20;
const allCakes = axios.get(`${ENTRY_POINT}/cakes.json`);

export default class Product {
  async getAllProducts() {
    try {
      const res = await allCakes;

      return res.data;
    } catch (e) {
      console.log(e)
      console.log('Error while getting products');

    }
  }

  async productsFilter(categorySlug, subcategorySlug, type, sortType, pageNumber = 1, itemsPerPage = ITEMS_PER_PAGE) {
    try {
      const res = await allCakes;

      let cakes = [];
      for (const key in res.data) {
        if (res.data.hasOwnProperty(key) && res.data[key].categorySlug.toLowerCase() === categorySlug.toLowerCase()) {
          cakes.push({...res.data[key]});
        }
      }

      if (subcategorySlug !== '') {
        cakes = cakes.filter(cake => cake.subCategorySlug === subcategorySlug);
      }
      if (type !== 'All') {
        cakes = cakes.filter(cake => cake.type === type);
      }

      if (sortType === 'az') {
        cakes.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
      } else if (sortType === 'za') {
        cakes.sort((a, b) => (a.name > b.name) ? -1 : ((b.name > a.name) ? 1 : 0));
      } else if (sortType === 'best-sellers') {
        cakes.sort((a, b) => (a.sold > b.sold) ? -1 : ((b.sold > a.sold) ? 1 : 0));
      } else if (sortType === 'search-magnitude') {
        cakes = cakes.slice().sort((a, b) => {
          return a.searchMagnitude - b.searchMagnitude;
        });
      }

      const totalProducts = cakes.length;
      const totalPages = Math.ceil(totalProducts / itemsPerPage);
      const currentPage = pageNumber;
      const startItemIndex = itemsPerPage * (pageNumber - 1);
      const cakesResult = cakes.slice(startItemIndex, startItemIndex + itemsPerPage);

      return {
        totalProducts, totalPages, currentPage, cakesResult
      };
    } catch (e) {
      console.log(e)
      console.log('Error while getting products');
    }
  }

  async searchProductsByName(searchTerm = '', type, sortType, pageNumber = 1, itemsPerPage = ITEMS_PER_PAGE) {
    try {
      const res = await await allCakes;

      let cakes = [];
      for (const key in res.data) {
        if ((res.data.hasOwnProperty(key) && res.data[key].name.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0)
            || (typeof res.data[key].searchKeywords === "string" && res.data[key].searchKeywords.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0)
            || (typeof res.data[key].searchKeywords === "object" && res.data[key].searchKeywords.toString().toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0)) {
          cakes.push({...res.data[key]});
        }
      }
      if (type !== 'All') {
        cakes = cakes.filter(cake => cake.type === type);
      }
      if (sortType === 'az') {
        cakes.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
      } else if (sortType === 'za') {
        cakes.sort((a, b) => (a.name > b.name) ? -1 : ((b.name > a.name) ? 1 : 0));
      } else if (sortType === 'best-sellers') {
        cakes.sort((a, b) => (a.sold > b.sold) ? -1 : ((b.sold > a.sold) ? 1 : 0));
      }
      const totalProducts = cakes.length;
      const totalPages = Math.ceil(totalProducts / itemsPerPage);
      const currentPage = pageNumber;
      const startItemIndex = itemsPerPage * (pageNumber - 1);
      const cakesResult = cakes.slice(startItemIndex, startItemIndex + itemsPerPage);
      return {
        totalProducts, totalPages, currentPage, cakesResult
      };
    } catch (e) {
      console.log(e)
      console.log('Error while searching for products');
    }
  }

  async getFirstCakeOfEachSubcategory(categorySlug, pageNumber = 1, itemsPerPage = ITEMS_PER_PAGE) {
    try {
      const res = await await allCakes;

      let cakes = [];
      for (const key in res.data) {
        if (res.data.hasOwnProperty(key) && res.data[key].categorySlug.toLowerCase() === categorySlug.toLowerCase()) {
          cakes.push({...res.data[key]});
        }
      }
      let subcategorySlugArr = [];
      cakes = cakes.filter(cake => {
        if (subcategorySlugArr.indexOf(cake.subCategorySlug.toLowerCase()) === -1
            && cake.categorySlug.toLowerCase() === categorySlug.toLowerCase()) {
          subcategorySlugArr.push(cake.subCategorySlug.toLowerCase());
          return true;
        }
      });
      const totalProducts = cakes.length;
      const totalPages = Math.ceil(totalProducts / itemsPerPage);
      const currentPage = pageNumber;
      const startItemIndex = itemsPerPage * (pageNumber - 1);
      const cakesResult = cakes.slice(startItemIndex, startItemIndex + itemsPerPage);
      return {
        totalProducts, totalPages, currentPage, cakesResult
      };
    } catch (e) {
      console.log('Error while getting first product of each subcategory');
    }
  }

  async getProductsOfEachSubcategory(categorySlug) {
    try {
      const res = await await allCakes;

      let cakes = [];
      for (const key in res.data) {
        if (res.data.hasOwnProperty(key) && res.data[key].categorySlug.toLowerCase() === categorySlug.toLowerCase()) {
          cakes.push({...res.data[key]});
        }
      }

      let subcategorySlugArr = [];
      cakes = cakes.filter(cake => {
        if (subcategorySlugArr.indexOf(cake.subCategorySlug.toLowerCase()) === -1
            && cake.categorySlug.toLowerCase() === categorySlug.toLowerCase()) {
          subcategorySlugArr.push(cake.subCategorySlug.toLowerCase());
          return true;
        }
      });

      const totalProducts = cakes.length;
      const cakesResult = cakes;
      const subCategoriesHasCakes = [];

      if (totalProducts > 0) {
        cakesResult.forEach(value => {
          if (subcategorySlugArr.includes(value.subCategorySlug)) {
            subCategoriesHasCakes.push({
              id: Date.now(),
              name: value.subCategory,
              parent: value.categorySlug.toLowerCase(),
              slug: value.subCategorySlug,
            });
          }
        });
      }
      return {
        totalProducts, cakesResult, subCategoriesHasCakes
      };
    } catch (e) {
      console.log('Error while getting first product of each subcategory');
    }
  }

  async getProductBySlug(slug) {
    try {
      const res = await await allCakes;
      let product = {};
      for (const key in res.data) {
        if (res.data.hasOwnProperty(key) && res.data[key].slug === slug) {
          product = {...res.data[key]};
        }
      }
      return product;
    } catch (e) {
      console.log(e)
      console.log('Error while getting products');
    }
  }
}
