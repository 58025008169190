<template>
  <section>
    <header>
      <h1>Data Salsify Seeding</h1>
    </header>
    <ul>
      <li :hidden="isSeedCategory == 0">
        <button @click="salsifyProductData" :disabled="isCategoryLoading || isProductLoading || isSeedCategory == 0">{{
            isProductLoading ? loadingProductMsg : 'Seeding Product Data'
          }}
        </button>
      </li>
      <li>
        <button @click="salsifyCategoryData" :disabled="isCategoryLoading || isProductLoading">{{
            isCategoryLoading ? loadingCategoryMsg : 'Seeding Category Data'
          }}
        </button>
      </li>
    </ul>
  </section>
</template>

<script>
import {ENTRY_POINT, SALSIFY_IMAGE_URI, SALSIFY_PROXY_END_POINT} from "@/constants/URLs";
import Category from "../../services/Category";
import axios from "axios";
import {
  sanitizeTitle,
  getRandomInt,
  getRandomBoolean,
  getIdFromProductSlug,
  generateDummyDescription
} from '@/utils/utils';

axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

export default {
  name: "SalsifyDataSeeding",
  data() {
    return {
      isProductLoading: false,
      isCategoryLoading: false,
      isSeedCategory: 0,
      loadingProductMsg: 'We are in Progressing update Product Data...',
      loadingCategoryMsg: 'We are in Progressing update Category Data ...',
      categories: [
        {
          name: 'Birthday',
          child: []
        }, {
          name: 'Characters',
          child: ['DC Comics', 'Disney', 'Marvel']
        }, {
          name: 'Occasions',
          child: ['Baby Shower', 'First Birthday', 'Graduation']
        }, {
          name: 'Seasons',
          child: ['Fall', 'Spring', 'Summer', 'Winter']
        }, {
          name: 'Sports',
          child: ['Baseball', 'Football', 'Hockey']
        }, {
          name: 'Themes',
          child: ['Dance', 'Dinosaurs', 'Princess', 'Sueper Heroes']
        }, {
          name: 'Varients',
          child: []
        },
      ]
    }
  },
  mounted() {
    if (localStorage.hasOwnProperty('isSeedCategory')) {
      this.isSeedCategory = localStorage.isSeedCategory;
    } else {
      localStorage.isSeedCategory = 0;
    }
  },
  methods: {
    salsifyProductData: async function () {
      this.isProductLoading = true;
      const cIQProucts = await axios.get(`${ENTRY_POINT}/cakes.json`);

      const categoryInstance = new Category();

      try {
        let currentPage = 1;
        let totalPages = 1;
        const salsifyProductsResponse = [];
        while (currentPage >= 1) {
          const apiResponse = await axios({
            method: 'GET',
            url: `${SALSIFY_PROXY_END_POINT}/salsify-product`,
            headers: {
              'Access-Control-Allow-Origin': '*',
              'Content-type': 'application/json',
            },
            params: {
              accessToken: 'k8ld2zC5MeqwmIEzLMsH-S82pGsQBHdHz8FFDeRyhLk',
              orgId: 's-c599e44b-be31-4ef9-8b23-123cf4a38542',
              page: currentPage,
              perPage: 100
            }
          });

          if (apiResponse.data.code !== 200) {
            const response = apiResponse.data.data;
            alert('ERROR! ' + response.error);
            return;
          }

          const response = JSON.parse(apiResponse.data.data);

          totalPages = Math.ceil(response.meta.total_entries / response.meta.per_page);

          if (response.data.length > 0) {
            salsifyProductsResponse.push(response.data);
          }

          if (currentPage > totalPages) {
            currentPage = 0;
            break;
          } else {
            currentPage++;
          }
        }

        let salsifyProducts = [];
        salsifyProductsResponse.forEach(array => {
          salsifyProducts = salsifyProducts.concat(array)
        });

        let salsifyExpandedProductItem = [];
        let salsifyCategories = [];
        let salsifyCategoryTagsArr = [];

        for (let index in salsifyProducts) {

          const salsifyId = salsifyProducts[index]['Cake Design ID'];
          const salsifyImageUrl = SALSIFY_IMAGE_URI + '' + (typeof salsifyProducts[index]['salsify:digital_assets'] !== "undefined" ? salsifyProducts[index]['salsify:digital_assets'][0]['salsify:name'].replace('.psd', '.jpg') : 'rgxjlz7iwuvypamqwush.jpg');
          const salsifyCakeName = (typeof salsifyProducts[index]['Cake Name'] !== "undefined" ? salsifyProducts[index]['Cake Name'] : salsifyId);

          const categoryTags = salsifyProducts[index]['Category Tags'];

          if (typeof categoryTags !== "undefined") {
            /*
            * DEFINE SALSIFY PRODUCT CHILDRENS
            * */
            const productChildrens = [];
            if (typeof salsifyProducts[index]['salsify:childrens'] !== "undefined") {
              const salsifyProductChildrens = salsifyProducts[index]['salsify:childrens'];
              salsifyProductChildrens.forEach(child => {
                const salsifyChildImageUrl = SALSIFY_IMAGE_URI + '' + (typeof child['salsify:digital_assets'] !== "undefined" ? child['salsify:digital_assets'][0]['salsify:name'].replace('.psd', '.jpg') : salsifyImageUrl);
                const salsifyChildCakeName = (typeof child['Cake Name'] !== "undefined" ? child['Cake Name'] : salsifyCakeName + ' ' + child['Cake Design ID']);
                productChildrens.push({
                  searchMagnitude: (typeof salsifyProducts[index]['Search Magnitude'] !== "undefined" ? salsifyProducts[index]['Search Magnitude'] : 0),
                  decopacCakeID: (typeof child['Cake Design ID'] !== "undefined" ? child['Cake Design ID'] : child['salsify:id']),
                  addablePhotoCake: false,
                  chokingHazardWarning: true,
                  description: (typeof child['Cake Description'] !== "undefined" ? child['Cake Description'] : salsifyProducts[index]['Cake Description']),
                  id: child['salsify:id'],
                  image: salsifyChildImageUrl,
                  isCakeSizeFormatAvailable: false,
                  isCupcakeSizeFormatAvailable: true,
                  name: salsifyChildCakeName,
                  slug: sanitizeTitle(salsifyChildCakeName, '-') + '-' + child['salsify:id'],
                  sold: 15.00,
                  type: "1/4 sheet",
                  searchKeywords: (typeof child['Search Keywords'] !== "undefined" ? child['Search Keywords'] : ""),
                });
              });
            }

            if (typeof categoryTags === 'object' && categoryTags.length > 0) {
              salsifyCategoryTagsArr = salsifyCategoryTagsArr.concat(categoryTags);
              const itemSearchMagnitude = (typeof salsifyProducts[index]['Search Magnitude'] !== "undefined" ? salsifyProducts[index]['Search Magnitude'] : 0);
              const itemCakeId = (typeof salsifyProducts[index]['Cake Design ID'] !== "undefined" ? salsifyId : salsifyId.concat('-' + index));
              const itemCakeDesc = (typeof salsifyProducts[index]['Cake Description'] !== "undefined" ? salsifyProducts[index]['Cake Description'] : '');
              const itemSearchKeywords = (typeof salsifyProducts[index]['Search Keywords'] !== "undefined" ? salsifyProducts[index]['Search Keywords'] : "");
              categoryTags.forEach((itemTag, index) => {
                salsifyExpandedProductItem.push({
                  searchMagnitude: itemSearchMagnitude,
                  decopacCakeID: itemCakeId,
                  addablePhotoCake: false,
                  category: itemTag,
                  categorySlug: '',
                  chokingHazardWarning: true,
                  description: itemCakeDesc,
                  id: salsifyId.concat('-' + index),
                  image: salsifyImageUrl,
                  isCakeSizeFormatAvailable: false,
                  isCupcakeSizeFormatAvailable: true,
                  name: salsifyCakeName,
                  slug: sanitizeTitle(salsifyCakeName, '-') + '-' + salsifyId.concat('-' + index),
                  sold: 15.00,
                  subCategory: '',
                  subCategorySlug: '',
                  type: "1/4 sheet",
                  searchKeywords: itemSearchKeywords,
                  salsifyChildrens: productChildrens
                });
              });
            } else if (typeof categoryTags === 'string' && categoryTags.trim() !== '') {
              salsifyCategoryTagsArr.push(categoryTags);
              salsifyExpandedProductItem.push({
                searchMagnitude: (typeof salsifyProducts[index]['Search Magnitude'] !== "undefined" ? salsifyProducts[index]['Search Magnitude'] : 0),
                decopacCakeID: (typeof salsifyProducts[index]['Cake Design ID'] !== "undefined" ? salsifyProducts[index]['Cake Design ID'] : salsifyId),
                addablePhotoCake: false,
                category: salsifyProducts[index]['Category Tags'],
                categorySlug: '',
                chokingHazardWarning: true,
                description: salsifyProducts[index]['Cake Description'],
                id: salsifyProducts[index]['Cake Design ID'],
                image: salsifyImageUrl,
                isCakeSizeFormatAvailable: false,
                isCupcakeSizeFormatAvailable: true,
                name: salsifyCakeName,
                slug: sanitizeTitle(salsifyCakeName, '-') + '-' + salsifyId,
                sold: 15.00,
                subCategory: '',
                subCategorySlug: '',
                type: "1/4 sheet",
                searchKeywords: (typeof salsifyProducts[index]['Search Keywords'] !== "undefined" ? salsifyProducts[index]['Search Keywords'] : ""),
                salsifyChildrens: productChildrens
              });

            }

          } else {
            // INVALID SALSIFY CAKE
            // NOTHING TO DO
          }
        }

        /*
        * BEGIN: UPDATE SALSIFY CATEGORIES
        * */
        salsifyCategoryTagsArr = [...new Set(salsifyCategoryTagsArr)];

        const promises = salsifyCategoryTagsArr.map(async (category) => {
          const categorySlug = sanitizeTitle(category, '-');
          const existedCategory = await categoryInstance.getCategoryBySlug(categorySlug);

          if (Object.keys(existedCategory).length === 0) {
            return salsifyCategories.push({
              name: category,
              parent: "",
              slug: categorySlug
            });
          }
        });
        await Promise.all(promises);

        salsifyCategories = [...salsifyCategories];

        const categoriesUpdatePromiseArr = salsifyCategories.map((category, index) => {
          const id = 'PCD-' + index;

          const body = {
            [id]: {
              ...category,
              name: category.name,
              parent: category.parent,
              slug: category.slug
            }
          }
          // return axios.patch(`${ENTRY_POINT}/categories.json`, body);
        });

        /*
        * END: UPDATE SALSIFY CATEGORIES
        * */
        const promise = salsifyExpandedProductItem.map(async (product, index) => {
          const categoryTagItem = product.category;
          if (typeof categoryTagItem === 'string' && categoryTagItem.trim() !== '') {
            const categorySlug = sanitizeTitle(categoryTagItem);
            let existCategory = await categoryInstance.getCategoryBySlug(categorySlug);

            if (Object.keys(existCategory).length > 0) {
              /*
              * CASE: categorySlug is parent
              * Find category childs
              * category = based on categorySlug
              * subcategory = existedSubCategories (join by ',' delimiter) IF categorySlug in existedSubCategories array
              * subcategory = based on categorySlug IF categorySlug NOT IN existedSubCategories array OR does not have any childs
              * */

              if (existCategory.parent === '') {
                product.category = product.subCategory = existCategory.name;
                product.categorySlug = product.subCategorySlug = existCategory.slug;
              } else {
                /*
                * CASE: categorySlug is child
                * Find category parent
                * category = parent
                * subcategory = based on categorySlug (subCategory)
                * */
                let existCategoryParent = await categoryInstance.getCategoryBySlug(existCategory.parent);
                const existSubCategory = existCategory;

                product.category = existCategoryParent.name;
                product.categorySlug = existCategoryParent.slug;

                product.subCategory = existSubCategory.name;
                product.subCategorySlug = existSubCategory.slug;
              }
            }
          }
          return salsifyExpandedProductItem[index] = product;
        });
        await Promise.all(promise);

        // SORTING BY MAGNITUDE PROPERTY
        salsifyExpandedProductItem = salsifyExpandedProductItem.slice().sort(function (a, b) {
          return a.searchMagnitude - b.searchMagnitude;
        });
        salsifyExpandedProductItem = [...salsifyExpandedProductItem];

        const cakesUpdatePromiseArr = salsifyExpandedProductItem.map((cake, index) => {
          const id = 'CD' + index;

          const body = {
            [id]: {
              ...cake,
              type: cake.type === 'PhotoCake®' ? 'PhotoCakes' : cake.type,
              addablePhotoCake: cake.type === 'PhotoCake®' ? true : getRandomBoolean(),
              isCakeSizeFormatAvailable: cake.type === 'Cakes' ? true : getRandomBoolean(),
              isCupcakeSizeFormatAvailable: cake.type === 'Cupcakes' ? true : getRandomBoolean(),
              category: cake.category,
              categorySlug: cake.categorySlug,
              subCategory: cake.subCategory,
              subCategorySlug: cake.subCategorySlug,
              chokingHazardWarning: getRandomBoolean(),
            }
          }
          return axios.patch(`${ENTRY_POINT}/cakes.json`, body);
        });
        Promise.all(cakesUpdatePromiseArr)
            .then(res => {
              this.isProductLoading = false;
              alert('Seeding Data has been completed!');
              console.log('SEEDING PRODUCT COMPLETED');
              localStorage.isSeedCategory = 0;
              this.$router.go();
            });

      } catch (e) {
        this.isProductLoading = false;
        console.log(e)
        alert("Error while seeding Salsify Products data!");
      }
    },
    salsifyCategoryData: async function () {
      this.isCategoryLoading = true;
      const cIQDataReponse = await axios.get(`${ENTRY_POINT}/categories.json`);
      const cIQCategories = cIQDataReponse.data;
      const categoryInstance = new Category();

      const categoryFromSalsify = await axios({
        method: 'GET',
        url: `${SALSIFY_PROXY_END_POINT}/salsify-properties`,
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-type': 'application/json',
        },
        params: {
          accessToken: 'k8ld2zC5MeqwmIEzLMsH-S82pGsQBHdHz8FFDeRyhLk',
          orgId: 's-c599e44b-be31-4ef9-8b23-123cf4a38542',
          properties: 'Category Tags',
          'extras[page]': 1,
          'extras[per_page]': 100
        }
      });

      if (categoryFromSalsify.data.code === 200) {
        this.categories = [];
        for (const itemIdx in categoryFromSalsify.data.data) {
          this.categories.push(categoryFromSalsify.data.data[itemIdx]);
        }
      } else {
        const response = categoryFromSalsify.data.data;
        alert('ERROR! ' + response.error);
        return;
      }

      let newCategories = [];
      const getNewCategories = this.categories.map(async (category, index) => {
        const categorySlug = sanitizeTitle(category.name, '-');
        const existedCategory = await categoryInstance.getCategoryBySlug(categorySlug);
        // CHECK PARENT CATEGORY NOT EXISTED
        if (!existedCategory.hasOwnProperty('id')) {
          return newCategories.push({
            name: category.name,
            slug: categorySlug,
            parent: '',
            id: 'SalsifyCategory-' + index
          });
        }

        // CHECK SUBCATEGORIES EXISTED OR NOT
        if (category.child.length > 0) {
          category.child.forEach(async (child, subIndex) => {
            const subCategorySlug = sanitizeTitle(child);
            const newIndex = index + '-' + subIndex;
            const existedSubCategory = await categoryInstance.getCategoryBySlug(subCategorySlug);

            if (existedSubCategory.hasOwnProperty('slug') && existedSubCategory.parent !== categorySlug) {
              return newCategories.push({
                name: child,
                slug: subCategorySlug + '-2',
                parent: categorySlug,
                id: existedSubCategory.id
              });
            } else if (!existedSubCategory.hasOwnProperty('slug')) {
              return newCategories.push({
                name: child,
                slug: subCategorySlug,
                parent: categorySlug,
                id: 'SalsifyCategorySub-' + newIndex
              });
            }
          });
        }
      });

      await Promise.all(getNewCategories);

      let cIQCategoriesArr = [];
      for (const key in cIQCategories) {
        cIQCategories[key]['id'] = key;
        cIQCategoriesArr.push(cIQCategories[key]);
      }

      cIQCategoriesArr = cIQCategoriesArr.concat(newCategories);

      const updateCIQCategory = cIQCategoriesArr.map((item) => {
        const id = item.id;
        delete item["id"];

        const body = {
          [id]: {
            ...item
          }
        }

        return axios.patch(`${ENTRY_POINT}/categories.json`, body);
      });
      Promise.all(updateCIQCategory)
          .then(res => {
            this.isCategoryLoading = false;
            alert('Seeding Category Tags Data has been completed!');
            localStorage.isSeedCategory = 1;
            this.$router.go();
            console.log(res)
          });
    }
  }

}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Jost&display=swap');

* {
  box-sizing: border-box;
}

html {
  font-family: 'Jost', sans-serif;
}

body {
  margin: 0;
}

header {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  margin: 3rem auto;
  border-radius: 10px;
  padding: 1rem;
  background-color: #58004d;
  color: white;
  text-align: center;
  width: 90%;
  max-width: 40rem;
}

header h1 {
  color: #ffffff;
}

.content ul {
  margin: 0;
  padding: 0;
  list-style: none !important;
}

li,
form {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  margin: 1rem auto;
  border-radius: 10px;
  padding: 1rem;
  text-align: center;
  width: 90%;
  max-width: 40rem;
}

h2 {
  font-size: 2rem;
  border-bottom: 4px solid #ccc;
  color: #58004d;
  margin: 0 0 1rem 0;
}

button {
  font: inherit;
  cursor: pointer;
  border: 1px solid #ff0077;
  background-color: #ff0077;
  color: white;
  padding: 0.05rem 1rem;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.26);
}

button:hover,
button:active {
  background-color: #ec3169;
  border-color: #ec3169;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.26);
}

button[disabled] {
  background-color: #676767;
  border-color: #676767;
  pointer-events: none;
}

input {
  font: inherit;
  padding: 0.15rem;
}

label {
  font-weight: bold;
  margin-right: 1rem;
  width: 7rem;
  display: inline-block;
}

form div {
  margin: 1rem 0;
}
</style>