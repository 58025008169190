<template>
  <Fragment>
    <Sidedraw></Sidedraw>
    <Header></Header>
    <main
      class="content"
      :style="{ marginTop: calcMargin }"
    >
      <slot></slot>
    </main>
    <ModalMobile></ModalMobile>
    <Footer></Footer>
  </Fragment>
</template>

<script>
  import { mapState, mapMutations } from 'vuex';
  import { Fragment } from 'vue-fragment';
  import { SET_IS_SMALL_SCREEN_SIZE } from '@/store/mutation-types';
  import Header from './Header/Header';
  import Footer from './Footer/Footer';
  import Backdrop from '../UI/Backdrop/Backdrop';
  import Sidedraw from '../Sidedraw/Sidedraw';
  import BakeryInformation from '../../components/BakeryInformation/BakeryInformation';
  import ModalMobile from './Header/ModalMobile/ModalMobile';

  export default {
    name: 'Layout',
    components: { Fragment, Header, ModalMobile, Footer, Backdrop, Sidedraw, BakeryInformation },
    props: {
      width: {
        type: Number
      }
    },
    data() {
      return {
        windowWidth: window.outerWidth,
      }
    },
    created() {
      if (this.width >= 1024) {
        this.SET_IS_SMALL_SCREEN_SIZE({ isMobileScreenSize: false });
      } else {
        this.SET_IS_SMALL_SCREEN_SIZE({ isMobileScreenSize: true });
      }
    },
    mounted() {
      window.onresize = () => {
        this.windowWidth = window.outerWidth;
      }
    },
    watch: {
      width(to) {
        if (to >= 1024) {
          this.SET_IS_SMALL_SCREEN_SIZE({ isMobileScreenSize: false });
        } else {
          this.SET_IS_SMALL_SCREEN_SIZE({ isMobileScreenSize: true });
        }
      }
    },
    computed: {
      ...mapState({
        isDisplaySearchBar: state => state.layout.searchBarDesktop.isDisplay,
        isScrolled: state => state.layout.isScrolledDown,
        isBannerMobileDisplay: state => !state.layout.bakeryInfoIcons.isDisplay,
        isSearchInputMobileDisplay: state => state.layout.mobileSearchInput.isDisplay
      }),
      calcMargin() {
        if (this.isDisplaySearchBar && !this.isScrolled && this.width >= 1024) {
          return '11em';
        } else if (!this.isDisplaySearchBar && !this.isScrolled && this.width >= 1024) {
          return '5.5em';
        } else if (this.width < 1024 && this.isSearchInputMobileDisplay && !this.isScrolled) {
          return '11em';
        } else if (this.width < 1024 && this.isScrolled) {
          return '5.5em';
        } else if (this.width < 1024 && !this.isScrolled && !this.isSearchInputMobileDisplay) {
          return '5.5em';
        }
      },
      isShrink() {
        return (!this.isDisplaySearchBar && !this.isScrolled) || (!this.isScrolled && !this.isSearchInputMobileDisplay && this.width < 1024)
      },
      isExpand() {
        return !this.isShrink;
      }
    },
    methods: {
      ...mapMutations('layout', [SET_IS_SMALL_SCREEN_SIZE])
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.onResize);
    },
  }
</script>

<style lang="scss" scoped>
  .content {
    overflow: hidden;
    z-index: 100;
    position: relative;
    transition: margin 0.5s linear;
    -webkit-transition: margin 0.5s linear;
    -moz-transition: margin 0.5s linear;
    -o-transition: margin 0.5s linear;

    h1:not(:first-child) {
      margin-top: 0 !important;
    }
  }
</style>
