<template>
  <div class="icons-group">
    <div class="icon-group" @click="TOGGLE_SEARCH_BAR">
      <div class="icon-item" :class="{ 'top-09': isScrolled }">
        <MagnifyIcon></MagnifyIcon>
      </div>
      <transition name="fade">
        <p v-if="!isScrolled" class="icon-text left-search-text">Search</p>
      </transition>
    </div>
    <div class="icon-group" @click="TOGGLE_DESKTOP_DROPDOWN({ modalType: 'info' })">
      <div class="icon-item" :class="{ 'top-09': isScrolled }">
        <InfoIcon></InfoIcon>
      </div>
      <transition name="fade">
        <p v-if="!isScrolled" class="icon-text left-info-text">Info</p>
      </transition>
    </div>
    <div class="icon-group" @click="TOGGLE_DESKTOP_DROPDOWN({ modalType: 'map' })">
      <div class="icon-item" :class="{ 'top-09': isScrolled }">
        <MapPinIcon></MapPinIcon>
      </div>
      <transition name="fade">
        <p v-if="!isScrolled" class="icon-text left-map-text">Map</p>
      </transition>
    </div>
    <Dropdown></Dropdown>
  </div>
</template>

<script>
  import { mapState, mapMutations } from 'vuex';
  import { Fragment } from 'vue-fragment';
  import MagnifyIcon from '@/assets/images/magnify-ciq.svg';
  import MapPinIcon from '@/assets/images/MapPin.svg';
  import InfoIcon from '@/assets/images/InfoIcon.svg';
  import { SHOW_SEARCH_BAR, HIDE_SEARCH_BAR, TOGGLE_SEARCH_BAR, SHOW_DESKTOP_DROPDOWN, TOGGLE_DESKTOP_DROPDOWN, CLOSE_DESKTOP_DROPDOWN } from '../../../../store/mutation-types';
  import Dropdown from '../../../Dropdown/Dropdown';

  export default {
    components: { Fragment, MagnifyIcon, MapPinIcon, InfoIcon, Dropdown },
    computed: {
      ...mapState({
        address: state => state.bakery.bakery.shortAddress,
        isScrolled: state => state.layout.isScrolledDown
      })
    },
    methods: {
      ...mapMutations('layout', [SHOW_SEARCH_BAR, HIDE_SEARCH_BAR, TOGGLE_SEARCH_BAR, SHOW_DESKTOP_DROPDOWN, CLOSE_DESKTOP_DROPDOWN, TOGGLE_DESKTOP_DROPDOWN])
    }
  }
</script>

<style lang="scss" scoped>
  .icons-group {
    display: flex;
    flex-direction: row;
    align-items: center;

    @include larger_desktop {
      flex-basis: 10%;
    }

    @include large_desktop {
      flex-basis: 10%;
    }

    .icon-group {
      position: relative;
      overflow: visible;
      width: 4em;

      &:hover {
        cursor: pointer;
      }

      .icon-item {
        width: 1em;
        position: absolute;
        top: -1.3em;
        transition: top 500ms linear;
        -webkit-transition: top 500ms linear;
        -moz-transition: top 500ms linear;
        -o-transition: top 500ms linear;

        > svg {
          font-size: 15px;
        }
      }

      .icon-text {
        font-weight: bold;
        color: black;
        transition: top 500ms linear;
        -webkit-transition: top 500ms linear;
        -moz-transition: top 500ms linear;
        -o-transition: top 500ms linear;
        font-size: 16px;
        position: relative;
        top: -0.2em;
      }

      .icon-text:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background: #000;
        display: none;
      }

      .icon-text:hover:after {
        display: block;
      }

      .left-map-text {
        left: -10%;
      }

      .left-info-text {
        left: -8%;
      }

      .left-search-text {
        left: -21%;
      }

      .top-09 {
        top: -0.9em;
      }
    }
  }

  // transition
  .fade-enter-active, .fade-leave-active {
    transition: opacity 0.5s linear;
    -webkit-transition: opacity 0.5s linear;
    -moz-transition: opacity 0.5s linear;
    -o-transition: opacity 0.5s linear;
  }

  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
</style>
