<template>
	<div class="button-container">
		<button class="pinterest-button">Save Design on Pinterest</button>
	</div>
</template>

<script>
export default {
	name: "PinterestButton"
}
</script>

<style lang="scss" scoped>
	.button-container {
		.pinterest-button {
			color: white;
			background-color: #f9423a;
			cursor: pointer;
			border-radius: 4px;
			border: none;
			padding: 4px 6px;
		}
	}
</style>
