<template>
  <span class="pill" @click="clickPillHandler()">
    <span :class="isActive ? 'pill-text-active' : 'pill-text'">{{ item }}</span>
  </span>
</template>

<script>
  export default {
    props: {
      item: {
        type: String
      },
      index: {
        type: Number
      },
      isActive: {
        type: Boolean,
        default: false
      }
    },
    methods: {
      clickPillHandler() {
        this.$emit('click-pill-item', this.item, this.index);
      }
    }
  }
</script>

<style lang="scss" scoped>
  .pill {
    padding: 1em;
    background-color: #f4f0ed;
    border-radius: 4px;
    font-weight: 600;
    margin-right: 1em;
    margin-bottom: 1em;
    cursor: pointer;
    color: black;

    @include mobile {
      padding: 0.5em;
      margin-right: 0.5em;
    }

    @include small-mobile {
      padding: 0.5em;
      margin-right: 0.5em;
    }
  }

  .pill-text, .pill-text-active {
    position: relative;
  }

  .pill-text:after, .pill-text-active:after {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    height: 2px;
    background: #000;
  }

  .pill-text:after {
    display: none;
    bottom: -0.5;
  }

  .pill-text-active:after {
    display: block;
    bottom: 0.5;
  }

  .pill-text:hover:after {
    display: block;
  }
</style>
