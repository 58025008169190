<template>
  <div
    class="icons-mobile-group"
    :style="{ justifyContent: 'flex-end' }"
  >
    <div class="icon-mobile-group" @click="clickedSearchIconHandler">
      <div class="icon-mobile-item">
        <MagnifyIcon></MagnifyIcon>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState, mapMutations } from 'vuex';
  import { TOGGLE_MOBILE_SEARCH_INPUT, SHOW_MODAL_MOBILE, TOGGLE_MODAL_MOBILE, TOGGLE_SIDEDRAW_MOBILE, HIDE_MODAL_MOBILE } from '../../../../store/mutation-types';
  import MagnifyIcon from '@/assets/images/magnify-ciq-mobile.svg';
  import MapPinIcon from '@/assets/images/MapPin-mobile.svg';
  import InfoIcon from '@/assets/images/InfoIcon-mobile.svg';

  export default {
    components: { MagnifyIcon, MapPinIcon, InfoIcon },
    computed: {
      ...mapState({
        isDisplayBakeryInfoIcons: state => state.layout.bakeryInfoIcons.isDisplay,
        isDisplayModal: state => state.layout.sidedraw.isDisplay,
        isDisplayMobileSearch: state => state.layout.mobileSearchInput.isDisplay,
        isDisplayModalMobile: state => state.layout.modalMobile.isDisplay
      })
    },
    methods: {
      ...mapMutations('layout', [TOGGLE_MOBILE_SEARCH_INPUT, SHOW_MODAL_MOBILE, TOGGLE_MODAL_MOBILE, TOGGLE_SIDEDRAW_MOBILE, HIDE_MODAL_MOBILE]),
      clickedSearchIconHandler: function() {
        this.TOGGLE_MOBILE_SEARCH_INPUT();
        if (this.isDisplayModal || this.isDisplayModalMobile) {
          if (this.isDisplayModal) {
            this.TOGGLE_SIDEDRAW_MOBILE();
          }
          if (this.isDisplayModalMobile) {
            this.HIDE_MODAL_MOBILE();
          }
          if (!this.isDisplayMobileSearch) {
            this.TOGGLE_MOBILE_SEARCH_INPUT();
          }
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .icons-mobile-group {
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-right: 2em;
    padding-bottom: 1em;

    @include tablet {
      width: 20%;
    }

    @include mobile {
      width: 30%;
    }

    @include small-mobile {
      width: 50%;
    }

    .icon-mobile-group {
      display: flex;
      flex-direction: column;
      align-items: center;

      &:hover {
        cursor: pointer;
      }

      .icon-mobile-item {
        width: 100%;
        text-align: center;
      }
    }
  }
</style>
