<template>
  <p class="control has-icons-right" :class="{ 'float': isFloat, 'not-float': !isFloat }">
    <input class="input" type="text" @focus="showPlaceHolder = false" @blur="showPlaceHolder = true" @input="onChangedHandler($event)" @keyup="onKeyupHandler($event)" :value="value" ref="input" />
    <transition name="fade">
      <span @click="onClickedPlaceholderHandler" v-if="showPlaceHolder && value === ''" class="placeholder-text">What treat are you looking for?</span>
    </transition>
    <span class="icon is-small is-right">
      <i class="fas fa-search"></i>
    </span>
  </p>
</template>

<script>
  export default {
    data() {
      return {
        showPlaceHolder: true,
        value: ''
      }
    },
    props: {
      isFloat: {
        type: Boolean,
        default: true
      }
    },
    methods: {
      onChangedHandler(event) {
        this.value = event.target.value.trim();
      },
      onClickedPlaceholderHandler() {
        this.$refs.input.focus();
      },
			onKeyupHandler(e) {
      	if (e.key === 'Enter') {
      		this.value = "";
      		this.$refs.input.blur();
      		this.$router.push(`/cakes?term=${e.target.value}`);
				}
			}
    }
  }
</script>

<style lang="scss" scoped>
  .float {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 40%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
  }

  .not-float {
    margin: auto;
    height: 3em;
    width: 95%;
  }

  input {
    color: black;
  }

  input ~ .placeholder-text {
    position: absolute;
    color: rgb(168, 168, 168);
    left: 1em;
    top: 0.5em;
  }

  // transition
  .fade-enter-active, .fade-leave-active {
    transition: opacity 0.5s linear;
    -webkit-transition: opacity 0.5s linear;
    -moz-transition: opacity 0.5s linear;
    -o-transition: opacity 0.5s linear;
  }

  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
</style>
